import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';

import Button from '../../../components/navegation/buttons/Button';

const ParticipateGrid = props => {
    const { apps, title } = props;
    return (
        <article className='howToParticipate_inner'>
            <div>
                <h1><UseHTML html={title} /></h1>
                <div className='grid_participate'>
                    {apps?.map((item, id) => {
                        const { app_icon, app_name_link, app_link, app_name, app_description } = item;
                        return (
                            <div key={id} className='column_participate'>
                                <div>
                                    <i className={`${app_icon}`}></i>
                                    <p className='app_name'>{app_name}</p>
                                    <p className='app_info'>{app_description}</p>
                                    {app_link ? <Button to={`${app_link}`} className='blue'>{`${app_name_link}`}</Button> : ''}

                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </article>
    )
}

export default ParticipateGrid


