import React from 'react'
import UseLang from '../../../../../../components/hook/UseLang';

const PresentationButton = props => {
    const {item}= props;
    return (
        <div className='presentation_video_button'>
            {item.presentation && item.videos && item.videos_en && item.videos_pt_br && (
                <button className='button blue'><UseLang es>Videos y presentaciones</UseLang>
                    <UseLang en>Videos and presentations</UseLang>
                    <UseLang pt_br>Vídeos e apresentações</UseLang></button>
            )}
            {!item.presentation && item.videos && item.videos_en && item.videos_pt_br && (
                <button className='button blue'><UseLang es>Video</UseLang>
                    <UseLang en>Video</UseLang>
                    <UseLang pt_br>Vídeo</UseLang></button>
            )}
            {item.presentation && !item.videos && !item.videos_en && !item.videos_pt_br && (
                <button className='button blue'><UseLang es>Presentaciones</UseLang>
                    <UseLang en>Presentations</UseLang>
                    <UseLang pt_br>Apresentações</UseLang></button>
            )}
        </div>
    )
}

export default PresentationButton
