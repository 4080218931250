import React from 'react'
import PresentationTime from './PresentationTime';
import PresentationTitle from './PresentationTitle';
import Speakers from '../presentation-modal/Speakers';
import DynamicMeetingBoard from '../presentation-modal/DynamicMeetingBoard';
import PresentationButton from './PresentationButton';

const PresentationItem = props => {
    const { onClick, key, item, presentation_id } = props;
    return (
        <div key={key} className='presentation_item' onClick={onClick}>
            <PresentationTime start_time={item.start_time} ending_time={item.ending_time} />
            <PresentationTitle title={item} />
            <DynamicMeetingBoard dynamic={item.dynamic} format={item.format} room={item.room} interpretation={item.interpretation} languaje={item.languaje} live_streaming={item.live_streaming} element='button' />
            <Speakers presentation_id={presentation_id} instructor={item.instructor} speakers={item.speakers} moderator={item.moderator} modal={false} /> 
            <PresentationButton item={item} />
        </div>
    )
}

export default PresentationItem

