import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/homepage/content/HomePage';
import AboutLacnic39 from './pages/participate/aboutLacnic39/AboutLacnic39';
import Ftl from './pages/participate/ftl/Ftl';
import Info from './pages/hotels-travel/info/Info';
import HowToParticipate from './pages/participate/how-to-participate/HowToParticipate';
import NoMatch from './pages/no-match/NoMatch';
import LacnicFellowshipProgram from './pages/participate/lacnic-fellowship-program/LacnicFellowshipProgram';
import Programme from './pages/programme/agenda/programme/Programme';
import Visas from './pages/hotels-travel/visas/Visas';
import Hotels from './pages/hotels-travel/hotels/Hotels';
import Fees from './pages/registration/fees/Fees';
import './App.css' 
import Transport from './pages/hotels-travel/transport/Transport';
import FellowshipDraw from './pages/participate/fellowship-draw/FellowshipDraw';
import TravelDiscounts from './pages/hotels-travel/travel-discounts/TravelDiscounts'; 
import UploadBiography from './pages/programme/upload/biography/UploadBiography';
import UploadPresentation from './pages/programme/upload/presentations/UploadPresentation';
import Sponsorship from './pages/sponsorship/event-sponsorships/Sponsorship';
import PreparatoryActivity from './pages/programme/preparatory-activity/PreparatoryActivity'; 
import Speakers from './pages/programme/speakers/speakers-list/Speakers';
import { BiographyContext } from './context/DataContext';
import Bios from './pages/programme/speakers/bios/Bios';
import slugify from 'react-slugify';
import Attendees from './pages/registration/attendees/Attendees';
import WelcomeKit from './pages/participate/welcome-kit/WelcomeKit';
import LiveStreaming from './pages/multimedia/live-streaming/LiveStreaming';
import PresentationList from './pages/programme/agenda/presentations-list/PresentationsList';

 

function App() {
  const {bios} = useContext(BiographyContext)
  return (
    <Routes> 
      <Route path={`/`} element={<HomePage/>}> </Route>
      <Route path={`/es`} element={<HomePage/>} > </Route>
      <Route path={`/en`} element={<HomePage/>}> </Route>
      <Route path={`/pt-br`} element={<HomePage/>}> </Route>

      <Route path={`/es/como-participar/que-es-lacnic-39`} element={<AboutLacnic39/>}></Route>
      <Route path={`/es/como-participar/que-es-un-evento-de-lacnic`} element={<AboutLacnic39/>}></Route>
      <Route path={`/en/how-to-participate/about-lacnic-events`} element={<AboutLacnic39/>}></Route>
      <Route path={`/pt-br/como-participar/o-que-e-um-evento-do-lacnic`} element={<AboutLacnic39/>}></Route>

      <Route path={`/es/como-participar/ftl2023`} element={<Ftl/>}></Route>
      <Route path={`/en/how-to-participate/ftl2023`} element={<Ftl/>}> </Route>
      <Route path={`/pt-br/como-participar/ftl2023`} element={<Ftl/>}> </Route>

      <Route path={`/es/como-participar/como-participar-de-lacnic-39`} element={<HowToParticipate/>}></Route>
      <Route path={`/en/how-to-participate/how-to-participate-in-lacnic-39`} element={<HowToParticipate/>}></Route>
      <Route path={`/pt-br/como-participar/como-participar-do-lacnic-39`} element={<HowToParticipate/>}></Route>

      <Route path={`/es/como-participar/sorteo-de-becas-para-pequenos-isp-asociados-de-lacnic`} element={<FellowshipDraw/>}></Route> 
      <Route path={`/en/how-to-participate/draw-to-win-a-fellowship-for-small-isps`} element={<FellowshipDraw/>}></Route>  

      <Route path={`/es/como-participar/kit-de-bienvenida`} element={<WelcomeKit/>}></Route>
      <Route path={`/en/how-to-participate/welcome-kit`} element={<WelcomeKit/>}></Route>
      <Route path={`/pt-br/como-participar/kit-de-boas-vindas`} element={<WelcomeKit/>}></Route>

      <Route path={`/es/programa/agenda/`} element={<Programme event_id='48' />}>
        <Route path={`:session`} element={<Programme />} />
      </Route>
      <Route path={`/en/programme/agenda/`} element={<Programme event_id='49' />}>
        <Route path={`:session`} element={<Programme />} />
      </Route>
      <Route path={`/pt-br/programa/agenda/`} element={<Programme event_id='50' />}>
        <Route path={`:session`} element={<Programme />} />
      </Route> 

      <Route path={`/es/programa/presentaciones-y-videos`} element={<PresentationList   />}> 
      </Route>
      <Route path={`/en/programme/presentations-and-videos`} element={<PresentationList   />}> 
      </Route>
      <Route path={`/pt-br/programa/apresentacoes-e-videos`} element={<PresentationList   />}> 
      </Route> 


      <Route path={`/es/programa/que-es-un-evento-de-lacnic`} element={<AboutLacnic39/>}></Route>
      <Route path={`/en/programme/about-lacnic-events`} element={<AboutLacnic39/>}></Route>
      <Route path={`/pt-br/programa/o-que-e-um-evento-do-lacnic`} element={<AboutLacnic39/>}></Route>

      <Route path={`/es/programa/actividad-preparatoria`} element={<PreparatoryActivity/>}></Route>
      <Route path={`/en/programme/preparatory-activity`} element={<PreparatoryActivity/>}></Route>
      <Route path={`/pt-br/programa/atividade-preparatoria`} element={<PreparatoryActivity/>}></Route>

      <Route path={`/es/como-participar/programa-de-becas-de-lacnic`} element={<LacnicFellowshipProgram/>}>
        <Route path={`:fellowship`} element={<LacnicFellowshipProgram />} />
      </Route>
      <Route path={`/en/how-to-participate/lacnic-fellowship-program`} element={<LacnicFellowshipProgram/>}>
        <Route path={`:fellowship`} element={<LacnicFellowshipProgram />} />
      </Route>
      <Route path={`/pt-br/como-participar/programa-de-bolsas-do-lacnic`} element={<LacnicFellowshipProgram/>}>
        <Route path={`:fellowship`} element={<LacnicFellowshipProgram />} />
      </Route>

      <Route path={`/es/hoteles-y-viaje/informacion-general`} element={<Info/>}></Route>
      <Route path={`/en/hotels-and-travel/general-information`} element={<Info/>}> </Route>
      <Route path={`/pt-br/hoteis-e-viagem/informacoes-gerais`} element={<Info/>}></Route>

      <Route path={`/es/hoteles-y-viaje/paises-y-regiones-que-requieren-visa-para-viajar-a-mexico`} element={<Visas/>} />
      <Route path={`/en/hotels-and-travel/countries-and-regions-that-require-a-visa-to-travel-to-mexico`} element={<Visas/>} />
      <Route path={`/pt-br/hoteis-e-viagem/paises-e-regioes-que-precisam-visto-para-viajar-para-o-mexico`} element={<Visas/>} />

      <Route path={`/es/hoteles-y-viaje/hoteles`} element={<Hotels/>} />
      <Route path={`/en/hotels-and-travel/hotels`} element={<Hotels/>} />
      <Route path={`/pt-br/hoteis-e-viagem/hoteis`} element={<Hotels/>} />

      <Route path={`/es/hoteles-y-viaje/transporte`} element={<Transport/>} />
      <Route path={`/en/hotels-and-travel/transport`} element={<Transport/>} />
      <Route path={`/pt-br/hoteis-e-viagem/transporte`} element={<Transport/>} />

      <Route path={`/es/hoteles-y-viaje/descuentos-en-pasajes`} element={<TravelDiscounts/>} />
      <Route path={`/en/hotels-and-travel/travel-discounts`} element={<TravelDiscounts/>} />
      <Route path={`/pt-br/hoteis-e-viagem/descontos-em-passagens`} element={<TravelDiscounts/>} />


      <Route path={`/es/registro/tarifa`} element={<Fees/>} />
      <Route path={`/en/registration/registration-fee`} element={<Fees/>} />
      <Route path={`/pt-br/inscricao/taxa-de-inscricao`} element={<Fees/>} />

      <Route path={`/es/registro/lista-de-inscritos/:id`} element={<Attendees/>} />
      <Route path={`/en/registration/attendees-list/:id`} element={<Attendees/>} />
      <Route path={`/pt-br/inscricao/lista-de-inscritos/:id`} element={<Attendees/>} />

      <Route path={`/es/registro`} element={<Fees/>} />
      <Route path={`/en/registration`} element={<Fees/>} />
      <Route path={`/pt-br/inscricao`} element={<Fees/>} />

      <Route path={`/es/patrocinios`} element={<Sponsorship/>} />
      <Route path={`/en/sponsorship`} element={<Sponsorship/>} />
      <Route path={`/pt-br/patrocinios`} element={<Sponsorship/>} />

      <Route path={`/es/oradores/lista-de-oradores`} element={<Speakers/>} />
      <Route path={`/en/speakers/speakers-list`} element={<Speakers/>} />
      <Route path={`/pt-br/palestrantes/lista-de-palestrantes`} element={<Speakers/>} />
      
      {bios?.map((item, id) => 
          <Route key={id} path={`/es/oradores/${slugify(item.title.rendered)}`} 
            element={<Bios page_is='orador' bios_data={item} />}> 
          </Route>  
      )}  
      {bios?.map((item, id) => 
          <Route key={id} path={`/en/speakers/${slugify(item.title.rendered)}`} 
            element={<Bios page_is='orador' bios_data={item} />}> 
          </Route>  
      )}  
      {bios?.map((item, id) => 
          <Route key={id} path={`/pt-br/palestrantes/${slugify(item.title.rendered)}`} 
            element={<Bios page_is='orador' bios_data={item} />}> 
          </Route>  
      )}  
      <Route path={`/es/oradores/subir-biografia`} element={<UploadBiography/>}></Route>
      <Route path={`/en/speakers/upload-biography`} element={<UploadBiography/>}></Route>
      <Route path={`/pt-br/palestrantes/carregar-biografia`} element={<UploadBiography/>}></Route>

      <Route path={`/es/oradores/subir-presentacion`} element={<UploadPresentation/>}></Route>
      <Route path={`/en/speakers/upload-presentation`} element={<UploadPresentation/>}></Route>
      <Route path={`/pt-br/palestrantes/carregar-apresentacao`} element={<UploadPresentation/>}></Route>

      <Route path={`/streaming`} element={<LiveStreaming/>}></Route>
      <Route path={`/es/streaming`} element={<LiveStreaming/>}></Route>
      <Route path={`/en/streaming`} element={<LiveStreaming/>}></Route>
      <Route path={`/pt-br/streaming`} element={<LiveStreaming/>}></Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

export default App;
