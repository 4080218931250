import React, { useContext,  useState } from 'react';
import { PostContext } from '../../../context/DataContext';
import Main from '../../../components/interface/main/Main';
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header';
import FtlAccordion from './FtlAccordion';
import FtlContent from './FtlContent';
import FtlButton from './FtlButton'  
import UsePost from '../../../components/hook/UsePost';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import UseLangLocation from '../../../components/hook/UseLangLocation';
import './ftl.css'
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';

const Ftl = props => { 
    const { content, handleApiURL, handleID, title, accordion, button, handleLoading, status } = useContext(PostContext)
    const [loading, setLoading] = useState(true);

    return ( 
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id='681' >
                <Header title={title} url_es='/es/como-participar/ftl2023' url_pt_br='/pt-br/como-participar/ftl2023' url_en='/en/how-to-participate/ftl2023' loading={loading} {...props} />
                <Main className='container' status={status} loading={loading}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}> 
                            <SponsorsSlider/>
                            <StickyHeader/>
                            <FtlContent title={title} content={content} />
                            <FtlAccordion title={title} accordion={accordion} />
                            <FtlButton button={button} /> 
                        </UseTimeOut>
                    </section>   
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation> 
    )
}

export default Ftl
