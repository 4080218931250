import React, {useContext} from 'react'; 
import SponsorList from '../../../components/sections/sponsors/SponsorList';
import SponsorsWrapper from '../../../components/sections/sponsors/SponsorsWrapper';
import UseLang from '../../../components/hook/UseLang';
import { SponsorContext } from '../../../context/DataContext';

function SponsorsContent(props) { 
    const { sponsors_lacnic39 } = useContext(SponsorContext) 
    return (
        <SponsorsWrapper>
            <h2>
                <UseLang es>Patrocinadores</UseLang>
                <UseLang en>Sponsors</UseLang>
                <UseLang pt_br>Patrocinadores</UseLang>
            </h2>
            <SponsorList sponsors={sponsors_lacnic39} className='sponsors_lacnic39' />
        </SponsorsWrapper>
    )
}

export default SponsorsContent
