import { useContext, useEffect } from 'react';
import { MenuContext, LangContext } from '../../context/DataContext';

const useMenuContext = () => {
  const { handleMenuLang, handleEventID, isMenu, eventID, menuLang, id, loading, isMenuApp } = useContext(MenuContext);
  const { myLang } = useContext(LangContext);

  useEffect((props) => {
    if (myLang === 'es') {
      handleMenuLang('es'); 
      handleEventID(48); 
    } else if (myLang === 'en') {
      handleMenuLang('en'); 
      handleEventID(49); 
    } else if (myLang === 'pt-br') {
      handleMenuLang('pt-br'); 
      handleEventID(50); 
    }
  }, [myLang, handleMenuLang, handleEventID]);

  return { isMenu, eventID, menuLang, id, loading, isMenuApp };
};

export default useMenuContext;
