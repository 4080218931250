import React, { useEffect } from 'react'
import LoadingHomePage from '../../pages/homepage/loading/LoadingHomePage';
import Spin from '../animations/loadings/Spin';

const UseTimeOut = props => {
    const { children, timeout, loading, setLoading, homepage, title } = props;
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false); 
             
        }, timeout);
        return () => clearTimeout(timer);
    }, [setLoading, timeout]);

    return (
        <>{!loading
            ?
            <>
                {title
                    ?
                    children
                    :
                    <article><Spin /></article>
                }
            </>
            :
            <>
                {homepage
                    ?
                    <LoadingHomePage />
                    :
                    <article>
                        <Spin />
                    </article>
                }
            </>
        }</>
    )
}

export default UseTimeOut
