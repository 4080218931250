import React, { useState } from 'react'
import UseHTML from '../../../components/hook/UseHTML';



const CopaDiscounts = props => {
    const { headers, contents } = props;

    const [copyClipBoard, setCopyClipBoard] = useState(true);

    const ClipBoard = () => {
        navigator.clipboard.writeText(contents[2])
        setCopyClipBoard(false)
        setTimeout(() => {
            setCopyClipBoard(true);
        }, 3000);
        setCopyClipBoard(false);
    } 
    
    return (
        <article className='copa_discounts'>
            <div>
                <UseHTML html={headers[0]} className="logo_copa_airlines" />
                <UseHTML html={contents[0]} className="info_discounts" />
                <div className='data_discounts'>
                    <div>
                        <a href={`${contents[1]}`} target="_blank" rel="noreferrer">
                            <UseHTML html={headers[1]} /> <UseHTML html={contents[1]} />
                        </a>
                    </div>
                    <div className='code_discount'>
                        <p className='hash'>#</p>
                        <UseHTML className='code_tag' html={contents[2]} />
                        <button className='copy_clipboard' onClick={() => ClipBoard()}> 
                            {copyClipBoard ? <UseHTML html={headers[2]} /> : <i className='icon-check'></i>}
                        </button>
                    </div>
                </div>
            </div>
        </article>
    )
}

export default CopaDiscounts
