import React from 'react'
import UseLang from '../../hook/UseLang';

const ToogleBoxOptions = props => {
    const { disable_es, active_es, disable_en, active_en, disable_pt_br, active_pt_br } = props;
    return (
        <p>
            <UseLang es>{disable_es} / {active_es}</UseLang>
            <UseLang en>{disable_en} / {active_en}</UseLang>
            <UseLang pt_br>{disable_pt_br} / {active_pt_br}</UseLang>
        </p>

    )
}

export default ToogleBoxOptions
