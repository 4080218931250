import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';

const CopaRestrictions = props => {
    const {headers, contents } = props;
    return (
        <article className='copa_restrictions'>
            <div>
                <h2>{headers[3]}</h2>
                <UseHTML html={contents[3]} />
            </div>
        </article>
    )
}

export default CopaRestrictions
