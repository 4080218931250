import React from 'react'
import Spin from '../../../components/animations/loadings/Spin' 
import WelcomeText from './WelcomeText';
import './loading-homepage.css'
import Organizers from '../../../components/design/organizers/Organizers';

function LoadingHomePage() {
    return (
        <div className='loading_homepage'>
            <div>  
                <Spin />
                <WelcomeText/>
                <Organizers/>
            </div>
        </div>
    )
}

export default LoadingHomePage
