import React, {useEffect, useState} from 'react' 
import Alert from '../../../../../components/interface/alert/Alert';
import axios from "axios";
import UseHTML from '../../../../../components/hook/UseHTML';

const PresentationRegistration = props => {
    const { id } = props;
    const [data, setData] = useState([])
    useEffect(() => {
        const fetchPosts = async () => { 
            const responseJSON = await axios.get(`https://apievt.lacnic.net/wp-json/wp/v2/sessions/${id}`);
            setData(responseJSON.data.acf) 
        }
        fetchPosts()
    }, [id])
    
    return (
        <>
            {data.session_registration ?
                <article>
                    <div>
                        <Alert blue>
                            <UseHTML html={data.about_session_registration}/>
                        </Alert>
                    </div>
                </article>
            : undefined}
        </>
    )
}

export default PresentationRegistration


                             