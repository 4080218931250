import React, { useContext, useState } from 'react';
import { PostContext } from '../../../../context/DataContext'; 
import UsePost from '../../../../components/hook/UsePost';
import Header from '../../../../components/navegation/header/Header';
import Footer from '../../../../components/navegation/footer/Footer';
import Main from '../../../../components/interface/main/Main'; 
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../../components/hook/UseTimeOut';
import './upload-biography.css'
import UseLangLocation from '../../../../components/hook/UseLangLocation';    
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider';
import UseHTML from '../../../../components/hook/UseHTML';  
// import UseJotForm from '../../../../components/hook/UseJotForm';  

const UploadBiography = props => { 
    const { handleID, title, handleApiURL, handleLoading, status, content } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
 
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/data/speakers' handleLoading={handleLoading} handleID={handleID} id='1387'>
                <Header title={title} loading={loading} url_en='/en/speakers/upload-biography' url_es='/es/oradores/subir-biografia' url_pt_br='/pt-br/palestrantes/carregar-biografia' {...props} />
                <Main className='container upload_biography' loading={loading} status={status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}> 
                            <SponsorsSlider/>
                            <article>
                                <div> 
                                    <h1>{title}</h1>  
                                    <UseHTML html={content} /> 
                                    {/* <UseJotForm id='70866365684975' title='LACNIC - Biografías' height='1000px' />  */}
                                </div>
                            </article>
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default UploadBiography
