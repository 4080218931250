import React from 'react';
import UseLang from '../../../components/hook/UseLang'; 

function WelcomeText() { 

    return (
        <p className='welcome_text'>
            <UseLang es>Bienvenidos a LACNIC 39, uno de los eventos de Internet más importantes de la región que reúne a expertos y representantes de la academia, la sociedad civil, el mundo empresarial, gobiernos y profesionales de la industria de las telecomunicaciones.</UseLang>
            <UseLang en>Welcome to LACNIC 39, one of the most important Internet events in the region which brings together experts and representatives of academia, civil society, the private sector, governments, and telecommunications industry professionals.</UseLang>
            <UseLang pt_br>Bem-vindos ao LACNIC 39, um dos eventos da Internet mais importantes da região, que reúne especialistas e representantes da academia, sociedade civil, mundo empresarial, governos e profissionais do setor das telecomunicações.</UseLang>
        </p>
    )
}

export default WelcomeText
