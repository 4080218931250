import React, { useState, useEffect } from 'react'
import axios from "axios";
import Ellipsis from '../../../animations/loadings/Ellipsis';
import TransferButtonData from './TransferButtonData';
//import StandSubMenu from './StandSubMenu';

const FetchDropdownLinks = props => {
    const { url_api, id_menu, item_category, menuLang, eventID } = props
    const [apiData, setApiData] = useState([])
    const [loading, setLoadApiData] = useState(false)
 
    useEffect(() => {
        const apiData = async () => {
            setLoadApiData(true);
            const responseJSON = await axios.get(`${url_api}/?lang=${menuLang}&our_events=${eventID}`)
            setApiData(responseJSON.data)
            setLoadApiData(false); 
        }
        apiData()
    }, [url_api, eventID, menuLang])

    return (
        <> {!loading ?<>
            <TransferButtonData menuLang={menuLang} id_menu={id_menu} data={apiData} item_category={item_category} />
            {/* <StandSubMenu id_menu={id_menu} /> */}</>
            : <Ellipsis />
        }
        </>
    )
}

export default FetchDropdownLinks
 

 