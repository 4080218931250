import React  from 'react'
import Modal from '../../../../components/interface/modal/Modal';
import Cocktail from './Cocktail';
import Runners from './Runners';
import Social from './Social';
import './social-activities.css'

const SocialActivities = props => {
    const { display, closeModal, socialActivity } = props;
    
    return (
        <Modal show={display} onCancel={closeModal} className={`socials_activities ${socialActivity === 'runners' ? 'lacnic_runners':'' ||socialActivity === 'social'? 'socia_event':'' || socialActivity === 'cocktail'? 'cocktail':''}`}>
            <div className='close_modal_social'>
                <button onClick={closeModal}><i className="icon-xmark" aria-hidden="true"></i></button>
            </div>
            {socialActivity === 'runners' ? <><Runners  /></> : undefined}
            {socialActivity === 'cocktail' ? <><Cocktail  /></> : undefined}
            {socialActivity === 'social' ? <><Social /></> : undefined} 
        </Modal>
    )
}

export default SocialActivities
