import React, { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom';
import EvraServiceRequest from '../../../services/EvraServiceRequest'
import ToogleBox from '../../../components/interface/toogle-box/ToogleBox'

const AttendeesList = props => { 
    const { id} = useParams()
    const [event, setEvent] = useState(false)
    const [boxState, setBoxState] = useState('disable')
    const [data, setData] = useState()

    const handleActive = (value) =>{
        setBoxState(value)
    }
     
    const handleToogleBox = (value) => {
        setEvent(value); 
        if (value === false) {
            handleActive('disable')
        }
        if (value === true) {
            handleActive('active')
        } 
    }  

  useEffect(() => {
  
    EvraServiceRequest.getAteendes(id, event)
        .then((res) => {
          if (res) {
           // console.log("res is:"+res);
            setData(res);
          }
        })
        .catch((err) => {
          console.log("There is a server error!");
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);
        
    return (
        <article>
            <div>
                <div>
                    <ToogleBox 
                        disable_es='Inscriptos' 
                        active_es='Asistentes' 
                        disable_en='Disable' 
                        active_en='Active' 
                        disable_pt_br='Disable' 
                        active_pt_br='Active'
                        onClick={() => handleToogleBox(!event)}
                        boxState={boxState} 
                    /> 
                    <table className="table">
                        <tbody> 
                            <tr>
                                <th>Nombre</th>
                                <th>Organización</th>
                                <th>Participación</th>
                                <th>Pais</th>
                            </tr>
                            {data?.map((attendee) => (
                                <tr>
                                    <td>{attendee.nombre}</td>
                                    <td>{attendee.organizacion}</td>
                                    <td>{attendee.tipoParticipacion}</td>
                                    <td>{attendee.pais}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </article>
    )
}

export default AttendeesList
