import React, { useContext,  useState } from 'react';
import { PostContext } from './../../../context/DataContext'
import Main from './../../../components/interface/main/Main';
import Footer from './../../../components/navegation/footer/Footer';
import Header from './../../../components/navegation/header/Header'; 
import UsePost from './../../../components/hook/UsePost';
import OrganizersElement from './../../../components/design/organizers/OrganizersElement';
import UseTimeOut from './../../../components/hook/UseTimeOut';
import UseLangLocation from './../../../components/hook/UseLangLocation'; 
import SponsorsSlider from './../../../components/sections/sponsors/SponsorsSlider';
import SponsorshipHeader from './SponsorshipHeader';
import './sponsorship.css'
import SponsorshipTable from './SponsorshipTable';
import SponsorshipContact from './SponsorshipContact';
import WhySponsor from './WhySponsor';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';

const Sponsorship = props => { 
    const {  handleApiURL, handleID, title,  handleLoading, status, content, accordion } = useContext(PostContext)
    const [loading, setLoading] = useState(true);

    const headers =  accordion?.map(info => info.label)
    const contents =  accordion?.map(info => info.content) 
 
    return ( 
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/data/sponsorship' handleLoading={handleLoading} handleID={handleID} id='1286' >
                <Header title={title} url_es='/es/patrocinios' url_pt_br='/pt-br/patrocinios' url_en='/en/sponsorship' loading={loading} {...props} />
                <Main className='container' status={status} loading={loading}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}>
                            <SponsorsSlider/> 
                                <StickyHeader/>
                                <SponsorshipHeader title={title} content={content}/>
                                <WhySponsor  headers={headers} contents={contents} /> 
                                <SponsorshipContact headers={headers} contents={contents} /> 
                                <SponsorshipTable headers={headers} contents={contents} />   
                        </UseTimeOut>
                    </section>   
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation> 
    )
}

export default Sponsorship
