import React from 'react' 
import ToogleBoxButton from './ToogleBoxButton'
import ToogleBoxOptions from './ToogleBoxOptions'

const ToogleBox = props => { 
    return (
        <div className='toogle_button_box'> 
            <ToogleBoxOptions {...props}/> 
            <ToogleBoxButton  {...props} /> 
        </div>
    )
}

export default ToogleBox
