import React, { useContext, useState } from 'react'
import { PostContext } from '../../../../context/DataContext';
import Main from '../../../../components/interface/main/Main'; 
import Header from '../../../../components/navegation/header/Header';
import Footer from '../../../../components/navegation/footer/Footer';
import UsePost from '../../../../components/hook/UsePost';  
import SessionHeader from './session-header/SessionHeader';
import Presentations from './presentations-content/Presentations'; 
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../../components/hook/UseTimeOut';
import UseLangLocation from '../../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider';
import './event-session.css'   
import StickyHeader from '../../../../components/interface/sticky-header /StickyHeader';

const EventSession = props => {
    const { session_id, current_session } = props;
    const { handleID, title, content, handleApiURL, handleLoading, langSlugA, langSlugB, status, sessionId, id } = useContext(PostContext);
    const [loading, setLoading] = useState(true) 
    const href = `${window.location.href}` 
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/sessions' handleLoading={handleLoading} handleID={handleID} id={session_id}>
                <Header
                    url_es={`/es/programa/agenda/${href.includes(`${window.location.host}/en`) === true  ? langSlugB : ''}${href.includes(`${window.location.host}/pt-br`) === true  ? langSlugB : ''}`}
                    url_en={`/en/programme/agenda/${langSlugA}`}
                    url_pt_br={`/pt-br/programa/agenda/${href.includes(`${window.location.host}/es`) === true  ? langSlugB : ''}${href.includes(`${window.location.host}/en`) === true  ? langSlugA : ''}`}
                    title={title} loading={loading} {...props} />
                <Main status={status} className='container event_session' loading={loading} >
                    <section> 
                        <UseTimeOut timeout='1000' loading={loading} setLoading={setLoading} title={title}>
                            <SponsorsSlider/>
                            <StickyHeader/>
                            <SessionHeader title={title} content={content} id={id}/>  
                            <Presentations session_id={sessionId} current_session={current_session} id={id}/>  
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}
export default EventSession 