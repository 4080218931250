
import React,{useState, useLayoutEffect, useRef, useEffect}  from 'react';
import UseHTML from '../../../components/hook/UseHTML';
import sponsorshipLacnicEventVideo from './patrocina-los-eventos-de-lacnic.mp4' 

const SponsorshipHeader = props => { 
    const ref = useRef(null);

    const {title, content}= props;
    const [ height, setHeight] = useState(0)
     
    useLayoutEffect(() => { 
        setHeight(ref.current.offsetHeight);
      }, []);

      useEffect(() => {
        function handleWindowResize() { 
          setHeight(ref.current.clientHeight);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

      const style = {
        'height': height, 
    }

  return (
    <div className='sponsorship_header' style={style}> 
        <video width="1000" height="240" className='sponsorship_video' autoPlay loop muted>
            <source src={sponsorshipLacnicEventVideo} type="video/mp4" ></source>
        </video>
        <div className='sponsorship_header_content'>
            <div className='sponsorship_header_inner'>
                <div ref={ref}> 
                    <h1>{title}</h1>
                    <UseHTML html={content}/>
                </div>
            </div>
        </div>
    </div>
  )
}
export default SponsorshipHeader