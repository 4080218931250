import React from 'react'
import MenuButtonItem from '../mainNavMenu/MenuButtonItem'
import FetchDropdownLinks from '../mainNavMenu/FetchDropdownLinks' 

const ItemsMenuMedia = props => {
    
    const {item_name, item_category, url_api, id_menu, menuLang, eventID} = props;
    return (
        <ul>
            <li className='media_menu_items'>
                <MenuButtonItem item_name={item_name} /> 
                <FetchDropdownLinks
                    url_api={url_api}
                    id_menu={id_menu}
                    menuLang={menuLang}
                    eventID={eventID}
                    item_name={item_name}
                    item_category={item_category} 
                /> 
            </li>
        </ul>
    )
}

export default ItemsMenuMedia