import React, {useContext}  from 'react';

import { TimeZoneContext } from '../../../../context/DataContext';
import UseLang from '../../../../components/hook/UseLang';
 

const HeaderTime = props => {
    const {item} = props 
    const {myTimeZone, utc} =  useContext(TimeZoneContext)
    if( item ==='header-time' ){
        return(
           <>  
            {myTimeZone === 'localtime'  ?   
            <LocalTime /> : 
            <UtcTime gmt={utc}/>
            }
           </> 
        )
    }
}

export default HeaderTime;

function LocalTime(){
    return(
        <>
            <UseLang es>Hora Mérida</UseLang>
            <UseLang en>Mérida Time</UseLang>
            <UseLang pt_br>Horário Mérida</UseLang>
        </>
    )
}
const UtcTime = props =>{
    const {gmt} = props
     
    return(
        <>
            <UseLang es>Hora {gmt}</UseLang>
            <UseLang en>{gmt} Time</UseLang>
            <UseLang pt_br>Horário {gmt}</UseLang>
        </>
    )
}

