import React from 'react';
import UseLang from '../../../../components/hook/UseLang';

const HeaderDates = props => {

    const { date, item, day } = props

    if (item === 'header-date') {
        return (<>
            {day === 'sunday' && <>
                <UseLang es>Domingo {date}</UseLang>
                <UseLang en>Sunday {date}</UseLang>
                <UseLang pt_br>Domingo {date}</UseLang>
            </>}
            {day === 'monday' && <>
                <UseLang es>Lunes {date}</UseLang>
                <UseLang en>Monday {date}</UseLang>
                <UseLang pt_br>Segunda {date}</UseLang>
            </>}
            {day === 'tuesday' &&
                <>
                    <UseLang es>Martes {date}</UseLang>
                    <UseLang en>Tuesday {date}</UseLang>
                    <UseLang pt_br>Terça {date}</UseLang>
                </>
            }
            {day === 'wednesday' && <>
                <UseLang es>Miércoles {date}</UseLang>
                <UseLang en>Wednesday {date}</UseLang>
                <UseLang pt_br>Quarta {date}</UseLang>
            </>}
            {day === 'thursday' &&
                <>
                    <UseLang es>Jueves {date}</UseLang>
                    <UseLang en>Thursday {date}</UseLang>
                    <UseLang pt_br>Quinta {date}</UseLang>
                </>
            }
            {day === 'friday' && <>
                <UseLang es>Viernes {date}</UseLang>
                <UseLang en>Friday {date}</UseLang>
                <UseLang pt_br>Sexta {date}</UseLang>
            </>}
        </>)
    }
}

export default HeaderDates;
