import React from 'react';
import DropdownLinks from './DropdownLinks';

const TransferButtonData = props => {
    const { item_category, menuLang, id_menu, data } = props;
    return (
        data.sort((a, b) => a.acf.menu_order - b.acf.menu_order)?.map((item) => {
            return (
                <DropdownLinks 
                    menuLang={menuLang}
                    item_category={item_category}
                    id_menu={id_menu}
                    enable_menu={item.menu}
                    slug={item.acf.slug}
                    rendered={item.title.rendered}
                    external_link={item.acf.external_link}
                    redirect_link={item.acf.redirect_link}
                />
            )
        })
    )
}

export default TransferButtonData



