import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FeeSlide from './FeeSlide';

const FeesSlider = props => {
  const { fees } = props;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrow: false,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1290,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      {fees ? <article>
        <div className='fees_slider'>
          <Slider {...settings}>
            {fees?.map((item, id) => {
              const { title, info, price, discount, discount_price, discount_info, deadline, registration_url, more_info, modal_window } = item;
              return (
                <div key={id}>
                  <FeeSlide
                    title={title}
                    info={info}
                    price={price}
                    discount={discount}
                    discount_price={discount_price}
                    discount_info={discount_info}
                    deadline={deadline}
                    registration_url={registration_url}
                    id={id}
                    more_info={more_info}
                    modal_window={modal_window}
                  />
                </div>
              )
            })}
          </Slider>
        </div>
      </article> : undefined}
    </>
  )
}

export default FeesSlider

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className='arrow_slide next_slide'>
      <button onClick={onClick}>
        <i className='icon-angle-right'></i>
      </button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className='arrow_slide prev_slide'>
      <button onClick={onClick}>
        <i className='icon-angle-left'></i>
      </button>
    </div>
  );
}