import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Main from '../../../components/interface/main/Main'; 
import AttendeesHeader from './AttendeesHeader';
import AttendeesList from './AttendeesList';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement'; 
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';

const Fees = props => { 
    const { handleID, title, handleApiURL, handleLoading, status  } = useContext(PostContext);
    const [loading, setLoading] = useState(true);

    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/data/registration' handleLoading={handleLoading} handleID={handleID} id='952'>
                <Header title={title} loading={loading} url_en='/en/registration/attendees-list' url_es='/es/registro/lista-de-inscritos' url_pt_br='/pt-br/inscricao/lista-de-inscritos' {...props} />
                <Main className='container main_registration' loading={loading} status={status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}> 
                            <SponsorsSlider /><StickyHeader/>
                            <AttendeesHeader title={title} /> 
                            <AttendeesList  /* Lista de asistentes  */ />
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default Fees
