import React from 'react';
import Button from '../../../components/navegation/buttons/Button'

const FtlButton = props => {
    const { button } = props;
    return (
        <article className='submit_proposal_ftl'>
            <div>
                <Button to={`${button.button_url}`} target='_blank' className='yellow'>{button.button_name}</Button>
            </div>
        </article>
    )
}

export default FtlButton
