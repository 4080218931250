import React, { useContext, useState } from 'react'; 
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost'
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header';
import Main from '../../../components/interface/main/Main'; 
import './how-to-participate.css'
import ParticipateGrid from './ParticipateGrid';    
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';

const HowToParticipate = props => { 
    const { handleApiURL, title, handleID, apps, handleLoading, status } = useContext(PostContext)  
    const [loading, setLoading] = useState(true);  
     
    return (
        <> 
           <UseLangLocation>
                <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id='630'>
                    <Header url_es='/es/como-participar/como-participar-de-lacnic-39' url_pt_br='/pt-br/como-participar/como-participar-do-lacnic-39' url_en='/en/how-to-participate/how-to-participate-in-lacnic-39' title={title} loading={loading} {...props} /> 
                    <Main  className='container howToParticipate' status={status} loading={loading}>
                        <section> 
                            <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}> 
                                <SponsorsSlider/>
                                <StickyHeader/>
                                <ParticipateGrid  apps={apps} title={title} />
                            </UseTimeOut>
                        </section>
                    </Main>  
                    <OrganizersElement/>
                    <Footer className="master_foot foot_home" />
                </UsePost>
            </UseLangLocation> 
        </>
    )
}

export default HowToParticipate
