import React, {useContext, useState} from 'react';
import { TimeZoneContext } from '../../../../context/DataContext';
import UseLang from '../../../../components/hook/UseLang'
import ToogleButton from '../../../../components/interface/checkbox/ToogleButton'

const TimeCheckbox = props => {  
    const { handleTimeZone, myTimeZone } = useContext(TimeZoneContext)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    /* eliminar las comillas en localstorage {localStorage.getItem('timezone').replace(/['"]+/g, '')} */
    const [eventTime, setEventTime] = useState(false)

     
    const handleEventTime = (value) => {
        setEventTime(value);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (value === false) {
            handleTimeZone('localtime')
        }
        if (myTimeZone === 'localtime') {
            handleTimeZone(timezone)
        }
        if (myTimeZone === timezone) {
            handleTimeZone('localtime')
        }
        else if (value === true) {
            handleTimeZone(timezone)
        }
    }
  

    return (
        <article>
            <div className='table_tools'>
                <div className='toogle_button_box'>
                    <UseLang es><p>Zona horaria ({timezone})</p> </UseLang>
                    <UseLang en><p>Time zone ({timezone})</p> </UseLang>
                    <UseLang pt_br><p>Fuso horário ({timezone})</p> </UseLang> 
                    <ToogleButton myTimeZone={myTimeZone} onclick={() => handleEventTime(!eventTime)}  timezone={timezone} {...props} /> 
                </div>
            </div>
        </article>
    )
}

export default TimeCheckbox
 