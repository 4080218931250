import React from 'react'
import UseLang from '../../hook/UseLang'
import './organizers.css'

const Organizers = props => {
    return (
        <div className='organizers_logos'>
            <h4>
                <UseLang es>Organizadores</UseLang>
                <UseLang en>Organizers</UseLang> 
                <UseLang pt_br>Organizadores</UseLang>
            </h4>
            <div>
                <a href='https://www.lacnic.net/' target="_blank" rel="noreferrer"><img src="/images/lacnic-logo.svg" alt="LACNIC"></img></a>
                <a href="https://www.yucatan.gob.mx/" target="_blank" rel="noreferrer">
                    <UseLang es><img src="/images/logo-gobierno-de-yucatan.svg" alt="Gobierno del Estado de Yucatán"></img></UseLang>
                    <UseLang en><img src="/images/logo-gobierno-de-yucatan.svg" alt="Government of the State of Yucatán"></img></UseLang>
                    <UseLang pt_br><img src="/images/logo-gobierno-de-yucatan.svg" alt="Governo do Estado de Yucatán"></img></UseLang>
                </a>
            </div>
        </div>
    )
}

export default Organizers
