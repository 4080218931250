import React from 'react'
import UseLang from '../../../../components/hook/UseLang'
const Social = () => {  
    return (
        <>
            <div className='social_activity_image'>
            <img src="/images/pabellon-quinta-montes-molina.jpg" alt="Quinta Montes Molina" />
            </div> 
            <div className='social_activity_content'>
                <UseLang es>
                    <h1>Evento Social</h1>
                    <p className="presentation_start_time">10/5/2023, 20:00 - 24:00 (Mérida)</p>
                    <p>Quinta Montes Molina </p> 
                    <p>Paseo Montejo, Nº 469, entre Av. Colón y calle 35</p>
                     
                </UseLang>
                <UseLang en> 
                    <h1>Social Event</h1>
                    <p className="presentation_start_time">10/5/2023, 20:00 - 24:00  (Mérida)</p> 
                    <p>Quinta Montes Molina </p> 
                    <p>Paseo Montejo, No. 469, between Av. Colón and calle 35</p>
                </UseLang>
                <UseLang pt_br>
                <h1>Evento Social</h1> 
                    <p className="presentation_start_time">10/5/2023, 20:00 - 24:00  (Mérida)</p>
                    <p>Quinta Montes Molina </p> 
                    <p>Paseo Montejo, nº 469, entre Av. Colón e calle 35</p>
                </UseLang>
            </div>
        </>
    )
}

export default Social
