import React from 'react'
import UseHTML from '../../../../components/hook/UseHTML';
import UseLang from '../../../../components/hook/UseLang';

const BiosTemplate = props => {
    const { bio } = props;
    return (
        <>
            {
                bio.acf.picture_default === false ?  <figure> <img src={bio.acf.photo} className='photo' alt={bio.title.rendered} /></figure>

                    : <>
                        {bio.acf.sex === false ? <figure> <img src='/default-man.png' className='photo' alt={bio.title.rendered} /></figure> : <figure><img src='/default-woman.png' className='photo' alt={bio.title.rendered} /></figure>}
                    </>
            }

            <h1>{bio.title.rendered}</h1>
            {
                bio?.acf?.organization === 'false' ? undefined : <>{bio?.acf?.organization === 'LACNIC-STAFF'
                ? <figure><img src='/lacnic-staff.svg' className='lacnic_staff' alt='LACNIC STAFF' /></figure>
                : <p className='organization'>{bio?.acf?.organization}</p>}</>
            }
            
            {
                bio.acf.linkedin || bio.acf.twitter ?
                    <div className='social_networks'>
                        {bio.acf.linkedin ? <div><a href={`https://www.linkedin.com/in/${bio.acf.linkedin}`} target="_blank" rel="noreferrer"><i className='icon-linkedin-in'></i> {bio.acf.linkedin}</a></div> : undefined}
                        {bio.acf.twitter ? <div><a href={`https://twitter.com/${bio.acf.twitter}`} target="_blank" rel="noreferrer"><i className='icon-twitter'></i> {bio.acf.twitter}</a></div> : undefined}
                    </div>
                    : undefined
            }
            <UseLang es>
                <UseHTML html={bio.acf.biography_es} className='biography_about' />
            </UseLang>
            <UseLang en>
                <UseHTML html={bio.acf.biography_en} className='biography_about'  />
            </UseLang>
            <UseLang pt_br>
                <UseHTML html={bio.acf.biography_pt_br} className='biography_about' />
            </UseLang>
        </>
    )
}

export default BiosTemplate
