import React from 'react'
import UseHTML from '../../../components/hook/UseHTML'

const SponsorshipTable = props => {
    const { headers, contents } = props
    return (
        <article className='categories_and_benefit'>
            <div>
                <h2>{headers[0]}</h2>
                <figure><UseHTML html={contents[0]}/></figure>
            </div>
        </article>
    )
}

export default SponsorshipTable
