import React from 'react' 
import UseLang from '../../../../../../components/hook/UseLang'
import MyComponent from '../../../../../../components/interface/my-component/MyComponent';

const LiveStreamingButton = props => {
    const { live_streaming, element} = props;
    return (
        <>
            {live_streaming[0] ?
                <p className='live_streaming'>
                    <i className='icon-video'></i>
                    {live_streaming?.map(item => (
                        <span className='label' key={item.id}>
                            {item === 'live-streaming' &&
                                <><UseLang es>
                                    <MyComponent element={element} to='/streaming'>Transmisión en vivo</MyComponent>
                                </UseLang>
                                    <UseLang en>
                                        <MyComponent element={element} to='/streaming'>Live Streaming</MyComponent>
                                    </UseLang>
                                    <UseLang pt_br>
                                        <MyComponent element={element} to='/streaming'>Transmissão ao vivo</MyComponent>

                                    </UseLang>
                                </>}
                            {item === 'Zoom' && 
                                <MyComponent element={element} href="https://rir.la/lacnic39" target='_blank' rel="noreferrer">Zoom</MyComponent> 
                            }
                        </span>
                    ))}
                </p>
                : undefined}</>
    )
}

export default LiveStreamingButton
