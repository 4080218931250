import React, { useState, useEffect } from 'react';
import { SessionContext } from './DataContext';
import axios from "axios";

export const SessionState = ({ children }) => {

  const [dataSession, setDataSession] = useState([])
  const [isEvent, setEvent] = useState(48)
  const [isLang, setLang] = useState('es')
  const [status, setStatus] = useState([]);
  useEffect(() => {
    const loadOurEvents = async () => {
      const responseJSON = await axios.get(`https://apievt.lacnic.net/wp-json/wp/v2/sessions?per_page=20&lang=${isLang}&our_events=${isEvent}`)
      setDataSession(responseJSON.data)
      if (responseJSON.data.status) { setStatus(responseJSON.data.status); }
    }
    loadOurEvents()
  }, [isLang, isEvent])

  const session_slug = `${dataSession.map((item) => item.slug)}`
  /*  const session =  session_slug.split(',')[0] ; console.log(session_slug.split(',')[0]) */

  const handleLang = (value) => {
    setLang(value)
  }
  const handleEvent = (value) => {
    setEvent(value)
  }

  const [agendaData, setAgendaData] = useState([]);
  const [current_session, setCurrentSession] = useState(101);

  useEffect(() => {
    const loadOurEvents = async () => {
      if (typeof current_session !== 'undefined') { // Verificar que current_session no es undefined
        const responseJSON = await axios.get(`https://apievt.lacnic.net/wp-json/wp/v2/agenda?per_page=100&agenda_sessions=${current_session}&our_events=48`)
        setAgendaData(responseJSON.data)
      }
    }
    loadOurEvents()
  }, [current_session])

  const handleCurrentSession = (value) => {
    setCurrentSession(value)
  }

  const [presentations, setAgendaLACNIC40] = useState([])
  const [presentationLoading, setAgenda40Loading] = useState([])
  useEffect(() => {
    async function loadAgendaLACNIC40() {
      try {
        const agendaLACNIC40 = [];
        const response = await fetch(`https://apievt.lacnic.net/wp-json/wp/v2/agenda?our_events=${isEvent}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch sponsors: ${response.status} ${response.statusText}`);
        }
        const totalPages = response.headers.get('X-WP-TotalPages');
        for (let i = 1; i <= totalPages; i++) {
          const pageResponse = await fetch(`https://apievt.lacnic.net/wp-json/wp/v2/agenda?our_events=${isEvent}&page=${i}`);
          if (!pageResponse.ok) {
            throw new Error(`Failed to fetch sponsors for page ${i}: ${pageResponse.status} ${pageResponse.statusText}`);
          }
          const pagePresentation = await pageResponse.json();
          agendaLACNIC40.push(...pagePresentation);
        }
        setAgendaLACNIC40(agendaLACNIC40);
        setAgenda40Loading(true);
      } catch (error) {
        console.error(error);
      }
    }
    loadAgendaLACNIC40();
  }, [isEvent]);
  //console.log(presentations)
  return (
    <SessionContext.Provider value={{
      dataSession,
      handleLang,
      handleEvent,
      status,
      session_slug,
      setCurrentSession,
      agendaData,
      handleCurrentSession,
      presentations,
      presentationLoading
    }}>
      {children}
    </SessionContext.Provider>
  );
}

