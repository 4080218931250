import React from 'react'
import { useParams } from 'react-router-dom';
import slugify from 'react-slugify';
import UseHTML from '../../../components/hook/UseHTML'; 

const FellowshipProgramItem = props => {
    const {accordion, page_number} = props;
    const { fellowship } = useParams();
    return (
        <>
            {accordion?.map((item, id) => <>
                {
                    fellowship === slugify(item.label) && item.id === `${page_number}` &&
                    <div key={id}>
                        <h2><em>{item.label}</em></h2>
                        <UseHTML html={item.content} />
                    </div>
                }
                {
                    fellowship === undefined && item.id === '6' &&
                    <div key={id}>
                        <h2><em>{item.label}</em></h2>
                        <UseHTML html={item.content} />
                    </div>
                }
            </>
            )}
        </>
    )
}

export default FellowshipProgramItem
