import React, { useState } from 'react';
import { TimeZoneContext } from './DataContext';

export const TimeZoneState = ({ children }) => {
    if (localStorage.getItem('timezone') === null) {
        localStorage.setItem('timezone', JSON.stringify('localtime'));
        window.location.href = "/es/programa/agenda";
    }

    localStorage.getItem('timezone', JSON.stringify('localtime'));

    const defaultTime = 'localtime'
    const time = JSON.parse(localStorage.getItem('timezone') || defaultTime);

    const [myTimeZone, setMyTimeZone] = useState(time)


    const handleTimeZone = (value) => {
        localStorage.setItem('timezone', JSON.stringify(value));
        setMyTimeZone(value)
    }

    const gmtFormatter = new Intl.DateTimeFormat(navigator.language, { timeZoneName: 'short' });
    const [{ value: gmt }] = gmtFormatter.formatToParts(new Date()).filter(part => part.type === 'timeZoneName');

    let utc = gmt.replace('GMT', 'UTC')

    const date_time_zone = [ 
        {
            "gmt": "GMT-11",
            "time_zone": "Pacific/Midway"
        },
        {
            "gmt": "GMT-6",
            "time_zone": "America/Merida"
        } 
    ] 
    return (
        <TimeZoneContext.Provider value={{
            handleTimeZone, myTimeZone, gmt, utc, date_time_zone
        }}>
            {children}
        </TimeZoneContext.Provider>
    );
}

