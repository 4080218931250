import React, {useState} from 'react'
import { NavLink } from 'react-router-dom';
import slugify from 'react-slugify';

const FellowshipProgramMenu = props => {
    const { accordion } = props  

    const [active, setActive] = useState(null);

    const handleMenu = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
 
    return (
        <nav className='fellowhip_program_menu'>
            <button className={`${active ==='open' ? 'active' : ''}`} onClick={() => handleMenu('open')}>Más información <i className='icon-angle-up'></i></button>
            <div className={`${active ==='open' ? 'show' : ''}`} > 
                <ul style={ active === 'open' ? {height: `100%`}: { height: "0px" }}> 
                    {accordion?.map((item, id) => <>
                        <li> <NavLink key={id} to={`./${slugify(item.label)}`}>{item.label}</NavLink></li>
                    </>)}
                
                </ul>  
            </div>
        </nav>
    )
}

export default FellowshipProgramMenu
