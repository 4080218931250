import React from 'react';
import './main.css'

function Main(props) {
    const {children, className, loading, status} = props
    return (
        <main 
            className=
                {`${className} ${!loading ? '' : 'main_charging'} ${status === 'publish' ? '' : 'main_charging'}`}
        >
            {children}
        </main>
    )
}

export default Main;
