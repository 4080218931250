import React, { useContext, useState, useEffect } from 'react';
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Main from '../../../components/interface/main/Main';
import FeesSlider from './FeesSlider';
import FeeHeader from './FeeHeader';
import FeeIncludes from './FeeIncludes';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import './fees.css'
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader'; 
import { useNavigate } from 'react-router-dom';


const Fees = props => { 
    const { handleID, title, handleApiURL, handleLoading, status, fees, content } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        navigate('?announcements=important-information-about-lacnic-39-registration');
    }, [navigate]); 
     
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/data/registration' handleLoading={handleLoading} handleID={handleID} id='903'>
                <Header title={title} loading={loading} url_en='/en/registration' url_es='/es/registro' url_pt_br='/pt-br/inscricao' {...props} />
                <Main className='container main_registration' loading={loading} status={status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}> 
                            <SponsorsSlider /><StickyHeader/>
                            <FeeHeader title={title} />
                            <FeesSlider fees={fees} />
                            <FeeIncludes content={content} /> 
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default Fees
