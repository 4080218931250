import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';

const WhySponsor = props => {
    const { headers, contents } = props;
    return (
        <article className='why_sponsor'>
            <div>
                <h2>{headers[1]}</h2>
                <UseHTML html={contents[1]} />
            </div>
        </article>
    )
}

export default WhySponsor
