import React, { useState, useContext } from 'react'
import { SessionContext } from '../../../../context/DataContext'
import Main from '../../../../components/interface/main/Main'
import Footer from '../../../../components/navegation/footer/Footer'
import Header from '../../../../components/navegation/header/Header'
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement'
import UseTimeOut from '../../../../components/hook/UseTimeOut'
import UseLangLocation from '../../../../components/hook/UseLangLocation'
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider'
import Videos from '../agenda-sessions/presentations-content/presentation-modal/Videos'
import Slides from '../agenda-sessions/presentations-content/presentation-modal/Slides'
import UseLang from '../../../../components/hook/UseLang'
import Speakers from '../agenda-sessions/presentations-content/presentation-modal/Speakers'

const PresentationList = props => {
    const { presentations } = useContext(SessionContext);
    const [loading, setLoading] = useState(true);
    const status = 'publish'
    const title = 'prueba'
    //console.log(presentations)
    const presentationsData = presentations?.sort((a, b) => {
        const sessionA = Array.isArray(a?.acf?.sessions) && a.acf.sessions.length > 0 ? a.acf.sessions[0].name : '';
        const sessionB = Array.isArray(b?.acf?.sessions) && b.acf.sessions.length > 0 ? b.acf.sessions[0].name : '';

        if (sessionA && sessionB) {
            const sessionComparison = sessionA.localeCompare(sessionB);
            if (sessionComparison !== 0) {
                return sessionComparison;
            }
        }

        return new Date(a?.acf?.start_time) - new Date(b?.acf?.start_time);
    });

    console.log(presentations)
    return (
        <UseLangLocation>
            <Header title={title} loading={loading} url_es='/es/programa/presentaciones-y-videos' url_en='/en/programme/presentations-and-videos' url_pt_br='/pt-br/programa/apresentacoes-e-videos' {...props} />
            <Main className='container schedule_table' status={status} loading={loading}  >
                <section>
                    <UseTimeOut timeout='1000' loading={loading} setLoading={setLoading} title={title}>
                        <SponsorsSlider />
                        <article><div>
                            <h1>
                                <UseLang es>Presentaciones y videos</UseLang>
                                <UseLang en>Presentations and videos</UseLang>
                                <UseLang pt_br>Apresentações e vídeos</UseLang>
                            </h1>
                            <figure className='presentationsList'>
                                <table className='table'>
                                    <tbody>
                                        <tr>
                                            <th><UseLang es>Sesión </UseLang>  <UseLang en>Session</UseLang> <UseLang pt_br>Sessão</UseLang> </th>
                                            <th><UseLang es>Título</UseLang> <UseLang en>Title</UseLang> <UseLang pt_br>Título</UseLang> </th>

                                            <th><UseLang es>Orador </UseLang>  <UseLang en>Speaker</UseLang> <UseLang pt_br>Palestrante</UseLang> </th>
                                            <th>Video  </th>
                                            <th><UseLang es>Presentación </UseLang>  <UseLang en>Presentation</UseLang> <UseLang pt_br>Apresentação</UseLang> </th>


                                        </tr>
                                        {presentations ?
                                            presentationsData?.map((item) => {
                                                const { id, acf } = item;
                                                return (
                                                    <>
                                                        {(acf?.presentation && acf?.videos && acf?.videos_en && acf?.videos_pt_br) && (
                                                            <tr key={id}>
                                                                <td>{Array.isArray(acf?.sessions) && acf.sessions.length > 0 && acf.sessions.map((session) => (
                                                                    <span key={session.id}>{session.name}</span>
                                                                ))}</td>
                                                                <td><UseLang es>{acf?.info_es?.title}</UseLang><UseLang en>{acf?.info_en?.title}</UseLang> <UseLang pt_br>{acf?.info_pt_br?.title}</UseLang> </td>
                                                                <td><Speakers presentation_id={acf?.id} instructor={acf?.instructor} speakers={acf?.speakers} moderator={acf?.moderator} modal={true} /></td>
                                                                <td><Videos videos_es={acf?.videos} videos_en={acf?.videos_en} videos_pt_br={acf?.videos_pt_br} /></td>
                                                                <td><Slides id={acf?.presentation} title_status={false} /></td>
                                                            </tr>
                                                        )}
                                                        {(!acf?.presentation && acf?.videos && acf?.videos_en && acf?.videos_pt_br) && (
                                                            <tr key={id}>
                                                                <td>{Array.isArray(acf?.sessions) && acf.sessions.length > 0 && acf.sessions.map((session) => (
                                                                    <span key={session.id}>{session.name}</span>
                                                                ))}</td>
                                                                <td><UseLang es>{acf?.info_es?.title}</UseLang><UseLang en>{acf?.info_en?.title}</UseLang> <UseLang pt_br>{acf?.info_pt_br?.title}</UseLang> </td>
                                                                <td><Speakers presentation_id={acf?.id} instructor={acf?.instructor} speakers={acf?.speakers} moderator={acf?.moderator} modal={true} /></td>
                                                                <td><Videos videos_es={acf?.videos} videos_en={acf?.videos_en} videos_pt_br={acf?.videos_pt_br} /></td>
                                                                <td></td>
                                                            </tr>
                                                        )}
                                                        {(acf?.presentation && !acf?.videos && !acf?.videos_en && !acf?.videos_pt_br) && (
                                                            <tr key={id}>
                                                                <td>{Array.isArray(acf?.sessions) && acf.sessions.length > 0 && acf.sessions.map((session) => (
                                                                    <span key={session.id}>{session.name}</span>
                                                                ))}</td>
                                                                <td><UseLang es>{acf?.info_es?.title}</UseLang><UseLang en>{acf?.info_en?.title}</UseLang> <UseLang pt_br>{acf?.info_pt_br?.title}</UseLang> </td>
                                                                <td><Speakers presentation_id={acf?.id} instructor={acf?.instructor} speakers={acf?.speakers} moderator={acf?.moderator} modal={true} /></td>
                                                                <td></td>
                                                                <td><Slides id={acf?.presentation} title_status={false} /></td>
                                                            </tr>
                                                        )}
                                                    </>
                                                )
                                            })
                                            : undefined}

                                    </tbody>
                                </table>
                            </figure>
                        </div></article>
                    </UseTimeOut>
                </section>
            </Main>
            <OrganizersElement />
            <Footer className="master_foot foot_home" />
        </UseLangLocation>
    )
}

export default PresentationList
