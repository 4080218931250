import React  from 'react'; 
import UseHTML from '../../../components/hook/UseHTML'; 
import AboutArticle from './AboutArticle';
import VideoSummary from './VideoSummary'


const AboutLacnic39Content = props => { 
    const {  html } = props;
    return ( 
        <AboutArticle>
            <VideoSummary /> 
            <UseHTML html={html}/> 
        </AboutArticle> 
    )
}

export default AboutLacnic39Content
