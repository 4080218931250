import React, {useState} from 'react' 
import PresentationItem from './PresentationItem';  
import PresentationModal from './PresentationModal';

const PresentationRow = props => {

    const { agendaData } = props;

    const [ presentationData, setPresentationData ]= useState(false)
    const handlePresentationData = (id) => {
        setPresentationData(id); 
        openModalData(true)
    } 

    const [ showData, setShowData ]= useState(false)
    const openModalData = () => setShowData(true)
    const closeModalData = () => setShowData(false)
    
    
    return ( 
        <article className='presentations'>
            <div className={`presentations_list`}>
                {agendaData?.sort((a, b) => new Date(a.acf.start_time) - new Date(b.acf.start_time))?.map((item, id) =>
                    <> 
                        <PresentationItem 
                            key={id} 
                            onClick={() => handlePresentationData(item?.id)}  
                            item={item?.acf}
                            presentation_id={item?.id}
                        />  
                    </>)
                }
                {agendaData?.map((item, id) =>
                    <> 
                        {presentationData === item?.id && 
                            <PresentationModal 
                            key={id}
                            show={showData} 
                            onCancel={closeModalData}
                            item={item?.acf}
                            presentation_id={item?.id}
                        /> 
                        }
                    </>)
                } 
            </div>
        </article> 
    )
}

export default PresentationRow
