import React, { useContext } from 'react';
import { SponsorContext } from '../../../context/DataContext';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './sponsors-slider.css'
import SponsorCategories from './SponsorCategories';

const SponsorsSlider = props => {
    const { sponsors_lacnic39 } = useContext(SponsorContext)

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 9,
        slidesToScroll: 1,
        initialSlide: 0,
        arrow: false,
        autoplay: true,
        autoplaySpeed: 4000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1390,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 890,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (<> 
        {sponsors_lacnic39 ? 
        <Slider className='sponsors_slider' {...settings}>
            {sponsors_lacnic39.sort((a, b) => a.acf.order_lacnic39 - b.acf.order_lacnic39)
                .map(sponsors => {
                    const { acf, title, id } = sponsors
                    return (
                        <div key={id} className="sponsor slick-slide">
                            <ul>
                                <li className='sponsor_list'>
                                    <a href={`${acf.url}`} target="_blank" className='sponsor_link' rel="noreferrer" title={`${title.rendered}`}>
                                        <img src={`${acf.logo}`} alt={`${title.rendered}`}/>
                                    </a>
                                    <div className='sponsors_categories'>
                                        <SponsorCategories id_category={`${acf.sponsor_category_lacnic39}`} />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )
                })}
        </Slider> 
        : <div></div>}</>
    )
}

export default SponsorsSlider

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className='arrow_slide next_slide'>
            <button onClick={onClick}>
                <i className='icon-angle-right'></i>
            </button>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className='arrow_slide prev_slide'>
            <button onClick={onClick}>
                <i className='icon-angle-left'></i>
            </button>
        </div>
    );
}  

 
  