import React, {  useState } from 'react'; 
import MediaScreenBars from './MediaScreenBars';
import MediaScreenMenuBox from './MediaScreenMenuBox';
import './media-screen-menu.css';
import ItemsMenuMedia from './ItemsMenuMedia'; 
import useMenuContext from '../../../hook/UseMenu'; 

const MediaScreenMenu = props => { 

    const { isMenu, eventID, menuLang, loading, id } = useMenuContext();

    const [show, setShow] = useState(false)

    const handleMenu = () => {
        setShow(!show)
    }

    return ( <> 
                <MediaScreenMenuBox show={show} onCancel={() => handleMenu(show)}>
                    <nav className='navMediaScreen'>
                        <button className='close_media_screen_menu' onClick={() => handleMenu(show)}>
                            <i className="icon-xmark" aria-hidden="true"></i>
                        </button>

                        {!loading ? <>
                            {isMenu.sort((a, b) => a.acf.id - b.acf.id).map((item) => {
                                if (Array.isArray(item.acf.event) && item.acf.event.includes(48)) {
                                    return (
                                        <>
                                            <ItemsMenuMedia
                                                id={id}
                                                url_api={item.acf.url_api}
                                                id_menu={item.id}
                                                menuLang={menuLang}
                                                eventID={eventID}
                                                event={`${item.acf.event}`}
                                                item_category={`${item.slug}`}
                                                item_name={`${item.name}`}
                                                {...props} />
                                        </>
                                    )
                                }else {
                                    return null;
                                }
                            })} 
                        </>
                            : undefined}

                    </nav>
                </MediaScreenMenuBox>
                <MediaScreenBars onClick={() => handleMenu(show)} /> 
                </>)
}

export default MediaScreenMenu
