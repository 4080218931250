import React,{useState, useLayoutEffect} from 'react'
import UseLang from '../../../../components/hook/UseLang' 


const Runners = props => {
    const [imagenUrl, setImagenUrl] = useState('/images/paseomontejo.jpeg');

    useLayoutEffect(() => {
    const actualizarImagen = () => {
      const anchoPantalla = window.innerWidth;
      if (anchoPantalla <= 768) {
        setImagenUrl('/images/paseo-montejo-casona.png');
      } else {
        setImagenUrl('/images/paseomontejo.jpeg');
      }
    };
    actualizarImagen();
    window.addEventListener('resize', actualizarImagen);
    return () => window.removeEventListener('resize', actualizarImagen);
  }, []);
    return (
        <>
            <div className='social_activity_image'>
                <img src={imagenUrl} alt="Paseo Montejo" />
            </div>
            <div className='social_activity_content'>
                <h1>LACNIC Runners</h1>
                <UseLang es>
                    <p className="presentation_start_time">10/5/2023, 05:50 (Mérida)</p>
                    <p>Los invitamos a unirse al grupo de runners de LACNIC en Mérida. En esta oportunidad, realizaremos una actividad libre de caminata o corrida por el <a href="https://www.merida.gob.mx/municipio/sitiosphp/merida/php/PaseoMontejo.phpx"  target='_blank' rel="noreferrer">Paseo de Montejo</a> una avenida histórica y emblemática de la ciudad.&nbsp;</p>
                    <p>Nos encontraremos el día miércoles 10 de mayo, a partir de las 05:50 am en el lobby del hotel Hyatt Regency Mérida. Partiremos a la hora 6.00 am hacia el sur, a modo de llegar hasta la calle 47 ubicada a 1,2 Km del hotel. A las 7.00 am regresaremos al Hyatt Regency Mérida.&nbsp;</p>
                    <p>Dado que el Paseo de Montejo se encuentra muy cercano al hotel, no contaremos con buses.&nbsp;</p>
                    <p>Para mantenerse informados sobre las actividades del grupo de runners, pueden inscribirse a la lista de runners en: </p>
                    <a className="button blue" href="https://mail.lacnic.net/mailman/listinfo/runners" target='_blank' rel="noreferrer">Registro</a>
                </UseLang>
                <UseLang en>
                    <p className="presentation_start_time">10/5/2023, 05:50 (Merida)</p>
                    <p>We invite you to join the group of LACNIC runners in M&eacute;rida.&nbsp;</p>
                    <p>In this opportunity, we will carry out a free activity of walking or running along the <a href="https://www.merida.gob.mx/municipio/sitiosphp/merida/php/PaseoMontejo.phpx"  target='_blank' rel="noreferrer">Paseo de Montejo</a>, a historic and emblematic avenue of the city.</p>
                    <p>We will meet on Wednesday 10th May at 05:50 am in the lobby of the Hyatt Regency M&eacute;rida hotel. We will leave at 6:00 am to the south, in order to reach Calle 47 located 1.2 km from the hotel.&nbsp;</p>
                    <p>At 7.00 we will return to the Hyatt Regency M&eacute;rida.</p>
                    <p>Since the Paseo de Montejo is very close to the hotel, we will not have buses.</p>
                    <p>To stay informed about the activities of the group of runners, you can sign up for the list of runners at: </p>
                    <a className="button blue" href="https://mail.lacnic.net/mailman/listinfo/runners"  target='_blank' rel="noreferrer">Registration</a> 
                </UseLang>
                <UseLang pt_br>
                    <p className="presentation_start_time">10/5/2023, 05:50 (Mérida)</p>
                    <p>Convidamos voc&ecirc; a se juntar ao grupo de corredores do LACNIC em M&eacute;rida.</p>
                    <p>Nesta oportunidade, vamos realizar uma atividade gratuita de caminhada ou corrida pelo <a href="https://www.merida.gob.mx/municipio/sitiosphp/merida/php/PaseoMontejo.phpx"  target='_blank' rel="noreferrer">Paseo de Montejo</a>, uma avenida hist&oacute;rica e emblem&aacute;tica da cidade.</p>
                    <p>Nos encontraremos na quarta-feira, 10 de maio, &agrave;s 05h50, no sagu&atilde;o do hotel Hyatt Regency M&eacute;rida. Sairemos &agrave;s 06h00 em dire&ccedil;&atilde;o ao sul para chegar &agrave; Calle 47 localizada a 1,2 km do hotel.</p>
                    <p>&Agrave;s 7h retornaremos ao Hyatt Regency M&eacute;rida.</p>
                    <p>Como o Paseo de Montejo fica muito perto do hotel, n&atilde;o teremos &ocirc;nibus.</p>
                    <p>Para se manter informado sobre o ato: </p>
                    <a className="button blue" href="https://mail.lacnic.net/mailman/listinfo/runners"  target='_blank' rel="noreferrer">Inscrição</a>  
                </UseLang>
            </div>
        </>
    )
}

export default Runners
