import React from 'react'

const AttendeesHeader = props =>{
    const {title} = props;
    return (
        <article className='fee_header'>
            <div>
                <h1>{title}</h1>
            </div>
        </article>
    )
}

export default AttendeesHeader
