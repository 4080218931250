import React, { useState, useEffect } from 'react'
import axios from "axios";
import UseLang from '../../../../../../components/hook/UseLang';
import Ellipsis from '../../../../../../components/animations/loadings/Ellipsis';
import ModalSlides from './ModalSlides';

const Slides = props => {
    const { id, title_status } = props;
    return (
        <>{id && <div className='slides'>
            <p className='slides_header'>
                <UseLang es>Presentaciones:</UseLang>
                <UseLang en>Presentation:</UseLang>
                <UseLang pt_br>Apresentação:</UseLang>
            </p> {id?.map((item) => <SlidesMap item={item} title_status={title_status}/>)}
        </div>}</> 

    )
}

export default Slides

const SlidesMap = props => {
    const { item, title_status } = props

    const [title, setTitle] = useState([])
    const [author, setAuthor] = useState([])
    const [source_url, setSourceUrl] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const slideFetch = async () => {
            setLoading(false)
            const responseJSON = await axios.get(`https://apievt.lacnic.net/wp-json/wp/v2/media/${item}`);
            setAuthor(responseJSON.data.acf.author);
            setTitle(responseJSON.data.acf.title)
            setSourceUrl(responseJSON.data.source_url)
            setLoading(true)
        }
        slideFetch()
    }, [item])

    const handleSlide = (item) => {
        openModal(true)
    }

    const [showSlide, setShowSlide] = useState(false)
    const openModal = () => setShowSlide(true)
    const closeModal = () => setShowSlide(false)


    return (
        <>
            {loading ?
                <>
                    <button className="source_url" onClick={() => handleSlide(item)}>
                        {title_status  ? 
                        <>
                         {title ?
                            <><i className='icon-file-pdf-1'></i> {`${title} `}
                                {author ? <>{author.length === 1 ?
                                    <><UseLang es>| Autor:</UseLang>
                                        <UseLang en>| Author:</UseLang>
                                        <UseLang pt_br>| Autor:</UseLang>
                                    </> :
                                    <><UseLang es>| Autores:</UseLang>
                                        <UseLang en>| Authors:</UseLang>
                                        <UseLang pt_br>| Autores:</UseLang>
                                    </>}
                                    {author.map((item, id) =>
                                        <span key={id}>
                                            {` ${item.post_title}`}
                                        </span>
                                    )}</> 
                                    : undefined}
                            </>
                            : <><i className='icon-file-pdf-1'></i> <UseLang es>Presentación</UseLang><UseLang en>Presentation</UseLang><UseLang pt_br>Apresentação</UseLang></>}
                        </>
                        : <><i className='icon-file-pdf-1'></i> <UseLang es>Presentación</UseLang><UseLang en>Presentation</UseLang><UseLang pt_br>Apresentação</UseLang></>}
                    </button>

                    <ModalSlides showSlide={showSlide} closeModal={closeModal} slide_id={item} url={source_url} />
                </>
                : <Ellipsis />}
        </>
    )
}