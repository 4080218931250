import React from 'react';  

function FootSocial() { 
    return ( 
        <ul className='foot_social'>
            <li><a href="https://twitter.com/lacnic" target='_blank' rel="noreferrer"><i className='icon-twitter'></i></a></li>
            <li><a href="https://facebook.com/lacnic" target='_blank' rel="noreferrer"><i className='icon-facebook-f'></i></a></li>
            <li><a href="https://uy.linkedin.com/company/lacnic" target='_blank' rel="noreferrer"><i className='icon-linkedin-in'></i></a></li>
            <li><a href="https://www.youtube.com/user/lacnicstaff" target='_blank' rel="noreferrer"><i className='icon-youtube'></i></a></li>
            <li><a href="https://www.instagram.com/lacnic/?hl=es-la" target='_blank' rel="noreferrer"><i className='icon-instagram'></i></a></li>
            <li><a href="https://podcasters.spotify.com/pod/show/lacnic/" target='_blank' rel="noreferrer"><i className='icon-podcast'></i></a></li>
        </ul> 
    )
}

export default FootSocial
