import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost';
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Main from '../../../components/interface/main/Main'; 
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut'; 
import UseLangLocation from '../../../components/hook/UseLangLocation';     
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import Webinar from './Webinar';
import './preparatory.css' 

const PreparatoryActivity = props => { 
    const { handleID, title, handleApiURL, handleLoading, status, accordion } = useContext(PostContext);
    const [loading, setLoading] = useState(true);

    const webinar_label =  accordion?.map(info => info.label)
    const webinar_content =  accordion?.map(info => info.content) 
 
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/programme' handleLoading={handleLoading} handleID={handleID} id='1339'>
                <Header title={title} loading={loading} url_en='/en/programme/preparatory-activity' url_es='/es/programa/actividad-preparatoria' url_pt_br='/pt-br/programa/atividade-preparatoria' {...props} />
                <Main className='container upload_biography' loading={loading} status={status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title} > 
                            <SponsorsSlider/> 
                            <Webinar webinar_label={webinar_label} webinar_content={webinar_content} /> 
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default PreparatoryActivity
