import React from 'react'
import UseHTML from '../../../components/hook/UseHTML';
import UseLang from '../../../components/hook/UseLang';

const Filters = props => {
    const { content, header } = props;
    return (
        <div className='filters'>
            <figure><img src='/images/filters.png' alt={header} /></figure>
            <div>
                <h2>{header}</h2>
                <UseHTML html={content} />
                <div>
                    <a href="https://bit.ly/lacnic39ig" target="_blank" rel="noreferrer" className='button blue'>
                        <UseLang es>
                            Marcos
                        </UseLang>
                        <UseLang en>
                            Frames
                        </UseLang>
                        <UseLang pt_br>
                            Marcos
                        </UseLang>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Filters
