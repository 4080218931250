import React, { useContext, useEffect, useState } from 'react'
import { SessionContext } from '../../../../../context/DataContext';
import './presentations.css'
import Ellipsis from '../../../../../components/animations/loadings/Ellipsis';
import TimeCheckbox from '../../table-functionalities/TimeCheckbox';
import PresentationRegistration from './PresentationRegistration';
import PresentationRow from './presentation-row/PresentationRow';

const Presentations = props => {
    const { current_session, id } = props;
    const { agendaData, handleCurrentSession } = useContext(SessionContext);
    handleCurrentSession(current_session)

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    
    return (
        <>{agendaData[0] && <>
            <>{!loading
                ?
                <>
                    <TimeCheckbox />
                    <PresentationRegistration id={id} /> 
                    <PresentationRow agendaData={agendaData}/> 
                </>
                :
                <article>
                    <div>
                        <Ellipsis />
                    </div>
                </article>
            }</>
        </>}
        </>
    )
}

export default Presentations
