import React, { useContext } from 'react';
import { SponsorContext } from '../../../context/DataContext';
import UseLang from '../../hook/UseLang';

const SponsorCategories = (props) => {
    const { id_category } = props;
    const { sponsors_categories } = useContext(SponsorContext)

    return (
        <>
            {sponsors_categories.map(item => {
                const { id, name, slug, acf } = item
                return (<>
                    {id_category.includes(id) &&
                        <p key={id} className={`sponsor_tag ${slug}`}>
                            <UseLang es>{name}</UseLang>
                            <UseLang en>{acf.name_en}</UseLang>
                            <UseLang pt_br>{acf.name_pt_br}</UseLang> 
                        </p>
                    }
                </>)
            })}
        </>
    )
}

export default SponsorCategories
