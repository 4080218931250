import React  from 'react'; 
import CountDown from '../../../components/interface/count-down/CountDown';
import './main-image.css'; 

const MainImage = props => {
    const {featured_content, featured_image} = props; 

    const bannerStyles = {
        'background': `url(${featured_image}) 0% 70% / cover no-repeat`,
    }

    return (
        <div className="main_image" style={bannerStyles}>
            <div className="main_image_backdrop_filter">
                <div>
                    <CountDown />
                    <div className='homepage_header'>
                        <h1>
                            {featured_content} 
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainImage
