import React from 'react'
import UseLang from '../../../../components/hook/UseLang';

const ActivitySlot = props => {
    const { item, name } = props;
    if (item === 'activity') {
        return (
            <>
                {name === 'Registro' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>Registration</UseLang>
                        <UseLang pt_br>Registro</UseLang>
                    </>
                }
                {
                    name === 'Nuevos participantes' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>New Participants</UseLang>
                        <UseLang pt_br>Novos participantes</UseLang>
                    </>
                }
                {
                    name === 'Tutoriales' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>Tutorials</UseLang>
                        <UseLang pt_br>Tutoriais</UseLang>
                    </>
                }

                {
                    name === 'Reunión Becados' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>Fellowship Program</UseLang>
                        <UseLang pt_br>Sessão bolsistas</UseLang>
                    </>
                }
                {
                    name === 'Apertura' &&
                    <>
                        <UseLang es>Apertura / Plenaria</UseLang>
                        <UseLang en>Opening / Plenary</UseLang>
                        <UseLang pt_br>Abertura / Plenário</UseLang>
                    </>
                }
                {
                    name === 'Plenaria' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>Plenary</UseLang>
                        <UseLang pt_br>Plenário</UseLang>
                    </>
                }
                {
                    name === 'Foro Técnico' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>Technical Forum</UseLang>
                        <UseLang pt_br>Fórum Técnico</UseLang>
                    </>
                }
                {
                    name === 'Foro Público' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>Policy Forum</UseLang>
                        <UseLang pt_br>Fórum Público</UseLang>
                    </>
                }
                {
                    name === 'Asamblea General' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>General Assembly</UseLang>
                        <UseLang pt_br>Assembleia Geral</UseLang>
                    </>
                }

                {
                    name === 'Internet en México, presente y futuro' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>Internet in Mexico, present and future</UseLang>
                        <UseLang pt_br>Internet no México, presente e futuro</UseLang>
                    </>
                }

                {
                    name === 'Almuerzo' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>Lunch</UseLang>
                        <UseLang pt_br>Almoço</UseLang>
                    </>
                }
                {
                    name === 'Cóctel de Bienvenida' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>Welcome Cocktail</UseLang>
                        <UseLang pt_br>Coquetel de Boas vindas</UseLang>
                    </>
                }
                {
                    name === 'Evento Social' &&
                    <>
                        <UseLang es>{name}</UseLang>
                        <UseLang en>Social Event</UseLang>
                        <UseLang pt_br>Evento Social</UseLang>
                    </>
                }
                {
                    name === 'Break' && 
                    <> 
                        <UseLang es>Receso</UseLang>
                        <UseLang en>{name}</UseLang>
                        <UseLang pt_br>Intervalo</UseLang>
                    </>
                }
                {name === 'BoF' && 
                    <>
                        <UseLang es>BoF de mediciones</UseLang>
                        <UseLang en>Measure-<br/>ments BoF</UseLang>
                        <UseLang pt_br>BoF de Mediciones</UseLang>
                    </>
                }
                {name === 'LACNIC Runners' && name}
                {name === 'LAC CSIRTs' && name} 
                {name === 'LAC PF' && name}
                {name === 'RISE Conf.' && name}
                {name === 'RISE Training' && name}
                {name === 'LACNIC' && name}
                {name === 'LACNOG' && name} 
            </>

        )
    }
}

export default ActivitySlot
