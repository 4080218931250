import React from 'react'; 
import EventStatistics from '../../../components/sections/statistics/EventStatistics';
import StatisticsWrapper from '../../../components/sections/statistics/StatisticsWrapper';
import UseLang from '../../../components/hook/UseLang';

function AboutStatistics() { 
    return (
        <StatisticsWrapper>
            <h2>
                <UseLang es>¿Qué pasó en LACNIC 38?</UseLang>
                <UseLang en>What happened at LACNIC 38?</UseLang>
                <UseLang pt_br>O que aconteceu no LACNIC 38?</UseLang>
            </h2>
            <EventStatistics />
        </StatisticsWrapper>
    )
}

export default AboutStatistics
