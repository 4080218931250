import React, { useContext,  useState } from 'react';
import { PostContext } from '../../../context/DataContext';
import Main from '../../../components/interface/main/Main';
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header'; 
import UsePost from '../../../components/hook/UsePost'; 
import InfoCover from './InfoCover';
import InfoAccordion from './InfoAccordion';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import './info.css'
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';

const Info = props => { 
    const { handleID, title, content, handleApiURL, accordion, handleLoading, status } = useContext(PostContext);
    const [loading, setLoading] = useState(true); 

    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/travel' handleLoading={handleLoading} handleID={handleID} id='584' >
                <Header title={title} url_en='/en/hotels-and-travel/general-information' url_pt_br='/pt-br/hoteis-e-viagem/informacoes-gerais' url_es='/es/hoteles-y-viaje/informacion-general' loading={loading} {...props} />
                <Main status={status} className='container info_general' loading={loading}  >
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}>
                            <StickyHeader />
                            <InfoCover loading={loading} title={title} content={content} />
                            <SponsorsSlider/>
                        
                            <InfoAccordion accordion={accordion} />
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default Info
