import  {useEffect, useState, useContext}  from 'react'; 
import { LangContext } from '../../context/DataContext';
 
import axios from "axios";

const UsePost = props => {
    const {   handleApiURL, url, handleID, id, children, handleLoading  } = props 
    const { myLang } = useContext(LangContext)

    const [postID_En, setPostID_En] = useState()
    const [postID_Pt_Br, setPostID_Pt_Br] = useState()
    const [loading, setLoading] = useState(false);   
    const [title, setTitle] = useState(''); 
    
    
    useEffect(() => {
        const documentTitle = document.title;
        document.title = `LACNIC 39 ${
          !loading
            ? `${
                title !== 'LACNIC 39' ? `-  ${title}` : ''
              }`
            : '- Charging'
        }`;
        return () => {
          document.title = documentTitle;
        };
      }, [title, loading]);

    useEffect(() =>{  
        const fetchPosts = async () =>{
            setLoading(true); 
            const responseJSON = await axios.get(`${url}/${id}`)  
            setPostID_En(responseJSON.data.wpml_translations[0].id)  
            setPostID_Pt_Br(responseJSON.data.wpml_translations[1].id)   
 
            if(myLang==='es'){setTitle(responseJSON.data.title.rendered)}
            if(myLang==='en'){setTitle(responseJSON.data.wpml_translations[0].post_title)}
            if(myLang==='pt-br'){setTitle(responseJSON.data.wpml_translations[1].post_title)}
 
            setLoading(false);
        } 
        fetchPosts() 
    }, [url, id, myLang])  
  
    handleLoading(loading);
  handleID(myLang === 'en' ? postID_En : myLang === 'pt-br' ? postID_Pt_Br : id);
  handleApiURL(url);

  return children;
 
}

export default UsePost;
