import React, { useState } from 'react'
import AccordionWrapper from '../../../components/interface/accordion/AccordionWrapper';
import AccordionItem from '../../../components/interface/accordion/AccordionItem';

const HotelsAccordion = props => {
    const { accordion } = props;

    const [active, setActive] = useState(null);
    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }
    return (
        <article className='hotels_accordion'>
            <AccordionWrapper>
                {accordion?.map((accordion, index) => {
                    return (
                        <AccordionItem
                            key={index} active={active}
                            handleToggle={handleToggle}
                            label={accordion.label}
                            id={accordion.id}
                            content={accordion.content} />
                    )
                })}
            </AccordionWrapper>
        </article>
    )
}

export default HotelsAccordion
