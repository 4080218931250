
import React  from 'react' 
/* import YouTube from 'react-youtube'; */
import lacnic38Video from './lacnic38-lacnog2022-resumen.mp4' 

export default function VideoSummary() {
  /* const videoOptions = {
    height: '420px',
      width: '100%' ,
    playerVars: {
      autoplay: 1,
      controls: 0,
      rel: 0,
      showinfo: 0,
      mute: 1,
      loop: 1 
       
    }
  }; 

  return ( 
      <YouTube videoId="346JlnEsNbY" opts={videoOptions} className='about_lacnic39_video'  /> 
   
  );  */
  return (
    <video width="1000" height="240" className='about_lacnic38_video' autoPlay loop muted>
      <source src={lacnic38Video} type="video/mp4" ></source>
    </video>
  )
}
