import React, { useState } from 'react';
import useUrlParams from '../../../hook/useUrlParams';
import Modal from '../../../interface/modal/Modal';
import { useNavigate } from 'react-router-dom';
import './stand.css';
import StandDates from './StandDates';
import StandTopics from './StandTopics';
import UseLang from '../../../hook/UseLang';

const Stand = props => {
    const navigate = useNavigate();
    const { paramValue, display, closeModal } = useUrlParams('lacnicStand', ['agenda'])
    const [activeTab, setActiveTab] = useState('8');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        navigate(`?lacnicStand=agenda&date=${tab}`);
    };

    return (
        <Modal show={display} paramValue={'lacnicStand'} className='lacnicStand' onCancel={closeModal}>
            {paramValue === 'agenda' ?
                <div>
                    <div className='stand_header'>
                        <button onClick={closeModal}><i className="icon-xmark" aria-hidden="true"></i></button>
                        <div>
                            <UseLang es><h2>Stand de LACNIC</h2>
                            <p>Durante toda la semana nuestros expertos ofrecerán información acerca de:</p></UseLang>
                            <UseLang en><h2>LACNIC Stand</h2><p>During the week our staff will be available to answer questions about:</p></UseLang>
                            <UseLang pt_br><h2>Estande de LACNIC</h2><p>Durante a semana toda, nossos especialistas vão oferecer informações sobre:</p></UseLang>
                        </div>
                    </div>
                    <div className='stand_agenda'>
                        <div className='stand_dates'>
                            <button className={`tab_button ${activeTab === '8' ? 'active' : ''}`} onClick={() => handleTabClick('8')}>08/5 <StandDates monday /></button>
                            <button className={`tab_button ${activeTab === '9' ? 'active' : ''}`} onClick={() => handleTabClick('9')}>09/5 <StandDates tuesday /></button>
                            <button className={`tab_button ${activeTab === '10' ? 'active' : ''}`} onClick={() => handleTabClick('10')}>10/5 <StandDates wednesday /></button>
                            <button className={`tab_button ${activeTab === '11' ? 'active' : ''}`} onClick={() => handleTabClick('11')}>11/5 <StandDates thursday /></button>
                            <button className={`tab_button ${activeTab === '12' ? 'active' : ''}`} onClick={() => handleTabClick('12')}>12/5 <StandDates friday /></button>
                        </div>
                        <div className='stand_topics'>
                            {activeTab === '8' &&
                                <div> 
                                    <div className='stand_start_time'>10.00 - 13.00 (Mérida)</div>
                                    <StandTopics ipv4 />
                                    <div className='stand_start_time'>14.00 - 15.00 (Mérida)</div>
                                    <StandTopics frida twoFA milacnic feedback  />
                                    <div className='stand_start_time'>15.00 - 17.00 (Mérida)</div>
                                    <StandTopics campus />
                                </div>
                            }
                            {activeTab === '9' &&
                                <div> 
                                    <div className='stand_start_time'>09.00 - 10.00 (Mérida)</div>
                                    <StandTopics ipv4 />
                                    <div className='stand_start_time'>10.00 - 11.00 (Mérida)</div>
                                    <StandTopics frida />
                                    <div className='stand_start_time'>11.00 - 12.00 (Mérida)</div>
                                    <StandTopics ipv4 />
                                    <div className='stand_start_time'>12.00 - 13.00 (Mérida)</div>
                                    <StandTopics campus ipv4/> 
                                    <div className='stand_start_time'>14.00 - 17.00 (Mérida)</div>
                                    <StandTopics campus twoFA milacnic feedback/>  
                                    <div className='stand_start_time'>17.00 - 18.00 (Mérida)</div>
                                    <StandTopics csirt/>  
                                </div>
                            }
                            {activeTab === '10' && 
                                <div> 
                                    <div className='stand_start_time'>09.00 - 11.00 (Mérida)</div>
                                    <StandTopics campus ipv4/> 
                                    <div className='stand_start_time'>11.00 - 12.00 (Mérida)</div>
                                    <StandTopics twoFA milacnic feedback rpki/> 
                                    <div className='stand_start_time'>14.00 - 16.00 (Mérida)</div>
                                    <StandTopics ipv6/>  
                                    <div className='stand_start_time'>16.00 - 18.00 (Mérida)</div>
                                    <StandTopics raices/>  
                                </div>
                            }
                            {activeTab === '11' && 
                                <div> 
                                    <div className='stand_start_time'>09.00 - 11.00 (Mérida)</div>
                                    <StandTopics campus />
                                    <div className='stand_start_time'>10.00 - 12.00 (Mérida)</div>
                                    <StandTopics ipv4 />
                                    <div className='stand_start_time'>12.00 - 13.00 (Mérida)</div>
                                    <StandTopics campus />
                                    <div className='stand_start_time'>14.00 - 16.00 (Mérida)</div>
                                    <StandTopics campus measurement/>  
                                    <div className='stand_start_time'>16.00 - 17.00 (Mérida)</div>
                                    <StandTopics twoFA milacnic feedback /> 
                                </div>
                            }
                            {activeTab === '12' && 
                                <div> 
                                    <div className='stand_start_time'>09.00 - 10.00 (Mérida)</div>
                                    <StandTopics frida />
                                    <div className='stand_start_time'>10.00 - 11.00 (Mérida)</div>
                                    <StandTopics campus ipv4 />
                                    <div className='stand_start_time'>12.00 - 13.00 (Mérida)</div>
                                    <StandTopics campus />
                                    <div className='stand_start_time'>15.00 - 17.00 (Mérida)</div>
                                    <StandTopics ipv4/>  
                                </div>
                            }
                        </div>
                    </div>
                </div>
                : undefined}

        </Modal>
    )
}

export default Stand
