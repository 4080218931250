import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost';
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Main from '../../../components/interface/main/Main';
import VisasAccordion from './VisasAccordion';
import VisasHeader from './VisasHeader'; 
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import './visas.css'
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';

const Visas = props => { 
    const { handleID, title, content, handleApiURL, handleLoading, status, accordion } = useContext(PostContext);
    const [loading, setLoading] = useState(true);
     
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/travel' handleLoading={handleLoading} handleID={handleID} id='846'>
                <Header title={title} loading={loading} url_en='/en/hotels-and-travel/countries-and-regions-that-require-a-visa-to-travel-to-mexico' url_es='/es/hoteles-y-viaje/paises-y-regiones-que-requieren-visa-para-viajar-a-mexico' url_pt_br='/pt-br/hoteis-e-viagem/paises-e-regioes-que-precisam-visto-para-viajar-para-o-mexico' {...props} />
                <Main className='container visas' loading={loading} status={status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}> 
                            <SponsorsSlider/>
                            <StickyHeader/>
                            <VisasHeader content={content} title={title} />
                            <VisasAccordion accordion={accordion} />
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default Visas
