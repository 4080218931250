import React  from 'react';
import UseHTML from '../../../components/hook/UseHTML';

const FtlContent = props => {
    
    const { title, content } = props
    return (
        <> {title ? <article className='ftl_content'>
            <div> 
                <h1><UseHTML html={title} /></h1>
                <UseHTML html={content} /> 
            </div>
        </article> : ''}
        </>
    )
}

export default FtlContent