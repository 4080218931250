import React from 'react'; 
import Cover from '../../../components/design/cover/Cover';
import UseHTML from '../../../components/hook/UseHTML';

const InfoCover = props => { 
    const { loading , title, content } = props 
     
    return (
        <Cover className='content' 
            loading={loading} 
            src='/images/merida-mexico-lacnic39.jpeg'> 
            <div>
                <h1><UseHTML html={title} /></h1>
                <UseHTML html={content} />
            </div> 
        </Cover>
    )
}

export default InfoCover
