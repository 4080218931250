import React, {useContext} from 'react'
import { LangContext, SessionContext } from '../../../../context/DataContext';
import { useNavigate } from 'react-router-dom';

const TableElement = props => {
     
         

    const {type, children, colspan, rowspan, name, item, event_id, day, width, social} = props;
    const { myLang } = useContext(LangContext)
    const {handleLang, handleEvent, session_slug} = useContext(SessionContext)
    handleLang(myLang) 
    handleEvent(event_id) 

    const th_styles = {
        'width': width 
    } 
    
    let navigate = useNavigate();
      
        const selectSession = () => {  
            if( name=== 'BoF'){
                return navigate(session_slug.split(',')[0]) 
            }
            if( name=== 'Tutoriales'  ){
                return navigate(session_slug.split(',')[1]) 
            }
            if( name=== 'RISE Conf.' || name === 'RISE Training' ){
                return navigate(session_slug.split(',')[2]) 
            }
            if( name=== 'LAC CSIRTs' ){
                return navigate(session_slug.split(',')[3]) 
            }
            if( name=== 'LACNIC' || name=== 'Apertura' || name=== 'Plenaria'){
                return navigate(session_slug.split(',')[4]) 
            }
            if( name=== 'Nuevos participantes'){
                return navigate(session_slug.split(',')[5]) 
            }
            if( name=== 'LACNOG'){
                return navigate(session_slug.split(',')[6]) 
            }
            if( name=== 'LAC PF'){
                return navigate(session_slug.split(',')[7]) 
            }
            if( name=== 'Internet en México, presente y futuro'){
                return navigate(session_slug.split(',')[8]) 
            }
            if( name=== 'Foro Técnico'){
                return navigate(session_slug.split(',')[9]) 
            }
            if( name=== 'Foro Público'){
                return navigate(session_slug.split(',')[10]) 
            }
            if( name=== 'Asamblea General'){
                return navigate(session_slug.split(',')[11]) 
            }
             
        }
         

    const Bkgds = `${
    name === 'Registro' ? 'BrownBkgd WhiteTxt' : '' 
    || name === 'Tutoriales' ? 'YellowBkgd mouse_enter' : '' 
    || name === 'BoF' ? 'OrangeBkgd mouse_enter' : '' 
    || name === 'Apertura' ? 'BlueBkgd mouse_enter' : ''
    || name === 'Nuevos participantes' ? 'BlueBkgd mouse_enter' : ''
    || name === 'Plenaria' ? 'BlueBkgd mouse_enter' : ''
    || name === 'Foro Técnico' ? 'BlueBkgd mouse_enter' : ''
    || name === 'Foro Público' ? 'BlueBkgd mouse_enter' : ''
    || name === 'Reunión Becados' ? 'BlueBkgd mouse_enter' : ''
    || name === 'Asamblea General' ? 'BlueBkgd mouse_enter' : ''
    || name === 'LACNIC' ? 'BlueBkgd mouse_enter' : ''
    || name === 'LACNOG' ? 'BlueBkgd mouse_enter' : ''
    || name === 'Internet en México, presente y futuro' ? 'BlueBkgd mouse_enter' : ''
    || name === 'LAC PF' ? 'OrangeBkgd mouse_enter' : ''
    || name === 'RISE Conf.' ? 'GreenBkgd mouse_enter' : ''
    || name === 'RISE Training' ? 'GreenBkgd mouse_enter' : ''
    || name === 'LAC CSIRTs' ? 'GreenBkgd mouse_enter' : ''
    || name === 'Break' ? 'BlackBkgd mouse_enter' : ''
    || name === 'Almuerzo' ? 'BlackBkgd mouse_enter' : ''
    || name === 'Cóctel de Bienvenida' ? 'VioletBkgd mouse_enter' : ''
    || name === 'Evento Social' ? 'VioletBkgd mouse_enter' : '' 
    || name === 'LACNIC Runners' ? 'VioletBkgd mouse_enter' : ''
    }`
 

    if (type === 'th'){
       return( <th 
        colSpan={colspan} 
        rowSpan={rowspan}
        className={`
        ${item === 'header-date' ? 'HeaderDate' : ''}
        ${item === 'header-time' ? 'HeaderDate' : ''}
        ${day  ? `${day}_column` : ''}
        `}
        style={th_styles}
        >
            {children}
    </th>)
    }
    if (type === 'td'){
       return( <td 
        onClick={
            selectSession
        }
        colSpan={colspan} 
        rowSpan={rowspan}
        className={
            ` 
            ${item === 'activity' ? Bkgds : 'TransparentBkgd'}  
            ${item === 'time' ? 'TimeZone' : ''}
            
            `
            
        }>
            {children}
    </td>)
    }
    if (type === 'social'){
        return(
            <td 
            onClick={social}
            colSpan={colspan} 
            rowSpan={rowspan}
            className={
                ` 
                ${item === 'activity' ? Bkgds : 'TransparentBkgd'}  
                ${item === 'time' ? 'TimeZone' : ''}
                
                `
                
            }>
                {children}
        </td>
    )}
}

export default TableElement
