import React, { useContext } from 'react';
import { TimeZoneContext } from '../../../../../../context/DataContext';
import { format } from 'date-fns-tz';

const PresentationTime = props => {
  const { start_time, ending_time } = props;
  const { myTimeZone, gmt, utc } = useContext(TimeZoneContext);

  // Convertir las cadenas de tiempo a objetos Date válidos
  const dateLocalStartTime = new Date(`${start_time} GMT-0600`);
  const dateUtcStartTime = new Date(`${start_time} ${gmt}`);
  const dateLocalEndingTime = new Date(`${ending_time} GMT-0600`);
  const dateUtcEndingTime = new Date(`${ending_time} ${gmt}`);

  // Formatear las fechas y horas según la zona horaria seleccionada
  const showStartTime = myTimeZone === 'localtime'
    ? format(dateUtcStartTime, 'HH:mm', { timeZone: gmt })
    : format(dateLocalStartTime, 'HH:mm');

  const showEndingTime = myTimeZone === 'localtime'
    ? format(dateUtcEndingTime, 'HH:mm', { timeZone: gmt })
    : format(dateLocalEndingTime, 'HH:mm');

  return (
    <p className='presentation_start_time'>
      {format(dateLocalStartTime, 'M/d/Y')},
      {showStartTime} - {showEndingTime}
      {myTimeZone === 'localtime' ? '(Mérida)' : `(${utc})`}
    </p>
  );
};

export default PresentationTime;
 
