import requests from "./httpService";

const EvraServiceRequest = {
  getSelectedFellows(idEventoTexto) {
    return requests.get(`${process.env.REACT_APP_BECADOS_URL}`+idEventoTexto);
  },
  getAteendes(idEventoTexto, asistentes) {
    return requests.get(`${process.env.REACT_APP_INSCRIPTOS_URL}`+idEventoTexto+`&asistentes=`+asistentes);
  },


};

export default EvraServiceRequest;
