import React from 'react'

const WelcomeKitHeader = props => {
    const { title } = props;
    return ( 
                <h1>{title}</h1>
             
    )
}

export default WelcomeKitHeader
