import React, { useState } from 'react';
import LoadBiography from './LoadBiography';
import SelectSpeaker from './SelectSpeaker';

const SpeakerName = ({ title, presentation_id, modal }) => {
    const [speaker_id, setSpeakerID] = useState('');
    const [showBio, setShowBio] = useState(false);

    const handleBio = (id) => {
        setSpeakerID(id);
        openModal();
    };

    const openModal = () => {
        setShowBio(true);
    };

    const closeModal = () => {
        setShowBio(false);
    };

    return (
        <>
            {title && modal ? (
                <div className='presentation_speakers'>
                    {title.map((item) => (
                        <React.Fragment key={item.ID}>
                            <SelectSpeaker title={item.post_title} onClick={() => handleBio(item.ID)} />
                        </React.Fragment>
                    ))}
                    <LoadBiography presentation_id={presentation_id} speaker_id={speaker_id} show={showBio} onCancel={closeModal} />
                </div>
            ) : (
                <>
                    {title &&
                        title.map((item) => (
                            <React.Fragment key={item.ID}>
                                <SelectSpeaker title={item.post_title} />
                            </React.Fragment>
                        ))}
                </>
            )}
        </>
    );
};

export default SpeakerName;
