import React from 'react'

import FellowshipProgramPagination from './FellowshipProgramPagination';
import FellowshipProgramMenu from './FellowshipProgramMenu';
import FellowshipProgramItem from './FellowshipProgramItem';

const FellowshipProgramContent = props => {
    const { accordion, page_number} = props;
    return ( 
        <article className='fellowship_program_content'>
            <div>
                <FellowshipProgramMenu accordion={accordion} page_number={page_number} />
                <div className='fellowhip_program_items'>
                    <FellowshipProgramItem accordion={accordion} page_number={page_number} />
                    <div className='fellowhip_program_pagination'>
                        <FellowshipProgramPagination accordion={accordion} page_number={page_number} />
                    </div> 
                </div>
                 
            </div>
        </article>  
    )
}

export default FellowshipProgramContent
