import React from 'react' 
import UseHTML from '../../../components/hook/UseHTML';

const HotelsHeader = props => {
    const { title } = props;
    return (
        <article className='hotels_header'>
            <div>
                <h1><UseHTML html={title} /></h1>
            </div>
        </article>
    )
}

export default HotelsHeader
