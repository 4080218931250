import React from 'react';
import FetchDropdownLinks from './FetchDropdownLinks';   

const Dropdown = props => {


    const { item_category, isItem, item_name, eventID, menuLang, id_menu, url_api } = props;
     
 
    return (
        <ul className={`mainNavMenu_dropdownBox ${isItem === item_category ? 'open-dropdown' : ''}`}  >
            <FetchDropdownLinks
                url_api={url_api}
                id_menu={id_menu}
                menuLang={menuLang}
                eventID={eventID}
                item_name={item_name}
                item_category={item_category} 
            />   
        </ul>
    )
}

export default Dropdown

