import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext';
import Main from '../../../components/interface/main/Main'
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header';
import AboutLacnic39Content from './AboutLacnic39Content';
import AboutSponsorsContent from './AboutSponsorsContent';
import AboutStatistics from './AboutStatistics';
import CodeOfConduct from './CodeOfConduct'; 
import UseLangLocation from '../../../components/hook/UseLangLocation';
import UsePost from '../../../components/hook/UsePost';
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import './about-lacnic.css'
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader'; 

const AboutLacnic39 = props => { 
    const {  content, handleApiURL, handleID, handleLoading, title, status } = useContext(PostContext); 
    const [loading, setLoading] = useState(true);  
 
    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id='699' >
                <Header title={title} url_en='/en/how-to-participate/about-lacnic-events' url_pt_br='/pt-br/como-participar/o-que-e-um-evento-do-lacnic' url_es='/es/como-participar/que-es-un-evento-de-lacnic' loading={loading} {...props} />
                <Main className='about_lacnic39_wrapper' status={status} loading={loading}> 
                    <section className='main_section'>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}>
                            <StickyHeader/>
                            <AboutLacnic39Content html={content} />
                            <SponsorsSlider/>
                            <AboutStatistics />
                            <AboutSponsorsContent />
                            <CodeOfConduct />
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost> 
        </UseLangLocation>
    )
}
export default AboutLacnic39;
