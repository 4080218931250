import React, { useState, useEffect } from 'react' 
import SocialActivities from '../social-activities/SocialActivities';
import TableCell from './TableCell'
import './timetable.css'

const Timetable = props => {
    const { eventTime } = props;
    const [socialActivity, setSelectedSocialActivity] = useState(null);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const option = params.get("socialActivity");  
  
        if (option) {
            if (option === 'runners' || option === 'social' || option === 'cocktail' ){
                setSelectedSocialActivity(option);
                setDisplay(true);
            } else {  
              window.location.href = '/error';
            }
          }
    }, [display]);

    const handleSocial = (option) => {
        setSelectedSocialActivity(option);
        setDisplay(true);

        // Actualizar los parámetros de consulta en la URL
        const params = new URLSearchParams({
            socialActivity: option
        });
        window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
         
    };

    const closeModal = () => {
        setDisplay(false);

        // Actualizar los parámetros de consulta en la URL 
        const params = new URLSearchParams(window.location.search);
        params.delete("socialActivity");
        params.delete("display");
        window.history.replaceState(null, null, window.location.href.split('?')[0]);
    };
 

    return (
        <article>
            <div className='table_bleed'>
                <div className='boxed_agenda'>
                    <table className='timetable'>
                        <tbody>
                            <tr>
                                <TableCell type='th' item="header-time" width='110px' eventTime={eventTime} {...props} />
                                <TableCell type='th' item="header-date" width='110px' day="sunday" date="7" {...props} />
                                <TableCell type='th' item="header-date" width='193px' day="monday" date="8" colspan="4" {...props} />
                                <TableCell type='th' item="header-date" width='140px' day="tuesday" date="9" {...props} />
                                <TableCell type='th' item="header-date" width='144px' day="wednesday" date="10" colspan="2" {...props} />
                                <TableCell type='th' item="header-date" width='224px' day="thursday" date="11" colspan="3" {...props} />
                                <TableCell type='th' item="header-date" width='101px' day="friday" date="12" {...props} />
                            </tr>

                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 05:50' eventTime={eventTime} {...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='empty' colspan="4" {...props} />
                                <TableCell type='td' item='empty'   {...props} />
                                <TableCell type='social' item='activity' social={() => handleSocial('runners')} name='LACNIC Runners' colspan="2"   {...props} />
                                <TableCell type='td' item='empty' colspan="3" {...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 07:00' eventTime={eventTime} {...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='empty' colspan="4" {...props} />
                                <TableCell type='td' item='empty'   {...props} />
                                <TableCell type='td' item='empty' colspan="2"   {...props} />
                                <TableCell type='td' item='empty' colspan="3"  {...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 08:00' eventTime={eventTime} {...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='activity' name='Registro' colspan="4" {...props} />
                                <TableCell type='td' item='activity' name='Registro'   {...props} />
                                <TableCell type='td' item='activity' name='Registro' colspan="2" rowspan="2" {...props} />
                                <TableCell type='td' item='activity' name='Registro' colspan="3" rowspan="2" {...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 08:30' eventTime={eventTime} {...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='activity' name='Nuevos participantes' colspan="4" {...props} />
                                <TableCell type='td' item='activity' name='Apertura' rowspan="2"  {...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 09:00' eventTime={eventTime} {...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='activity' name='Tutoriales' colspan="3" {...props} />
                                <TableCell type='td' item='activity' name='LAC PF' {...props} /> 
                                <TableCell type='td' item='activity' name='Internet en México, presente y futuro'  {...props} />
                                <TableCell type='td' item='activity' name='RISE Conf.' {...props} />
                                <TableCell type='td' item='activity' name='Foro Técnico' colspan="2" {...props} />

                                <TableCell type='td' item='activity' name='RISE Training' {...props} />
                                <TableCell type='td' item='activity' name='LACNOG' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 11:00' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='activity' name='Break' colspan='4'{...props} />
                                <TableCell type='td' item='activity' name='Break'  {...props} />
                                <TableCell type='td' item='activity' name='Break' colspan='2'{...props} />
                                <TableCell type='td' item='activity' name='Break' colspan='3'{...props} />
                                <TableCell type='td' item='activity' name='Break' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 11:30' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='activity' name='Tutoriales' colspan='3'{...props} />
                                <TableCell type='td' item='activity' name='LAC PF'  {...props} />
                                <TableCell type='td' item='activity' name='Foro Técnico'   {...props} />
                                <TableCell type='td' item='activity' name='Foro Público'  {...props} />
                                <TableCell type='td' item='activity' name='RISE Conf.' {...props} />
                                <TableCell type='td' item='activity' name='Foro Técnico' {...props} />
                                <TableCell type='td' item='activity' name='BoF' {...props} />
                                <TableCell type='td' item='activity' name='RISE Training' {...props} />
                                <TableCell type='td' item='activity' name='Tutoriales' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 13:00' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='activity' name='Almuerzo' colspan="4"{...props} />
                                <TableCell type='td' item='activity' name='Almuerzo'  {...props} />
                                <TableCell type='td' item='activity' name='Almuerzo' colspan='2'{...props} />
                                <TableCell type='td' item='activity' name='Almuerzo' colspan='3'{...props} />
                                <TableCell type='td' item='activity' name='Almuerzo'{...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 14:00' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='activity' name='Tutoriales' colspan="2" rowspan="2" {...props} />
                                <TableCell type='td' item='activity' name='LAC CSIRTs' {...props} rowspan="2" />
                                <TableCell type='td' item='activity' name='LAC PF' rowspan="2" {...props} />
                                <TableCell type='td' item='activity' name='Foro Técnico' rowspan="2" {...props} />
                                <TableCell type='td' item='activity' name='Foro Público' {...props} />
                                <TableCell type='td' item='activity' name='RISE Conf.' {...props} />
                                <TableCell type='td' item='activity' name='Foro Técnico' colspan="2" rowspan="2" {...props} />
                                <TableCell type='td' item='activity' name='RISE Training' rowspan="2"  {...props} />
                                <TableCell type='td' item='activity' name='Tutoriales' rowspan="2" {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 15:30' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='activity' name='Break' colspan='2'{...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 16:00' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='activity' name='Registro'  rowspan="3"{...props} />
                                <TableCell type='td' item='activity' name='Break' colspan='4'{...props} />
                                <TableCell type='td' item='activity' name='Break'  {...props} />
                                <TableCell type='td' item='activity' name='Asamblea General' rowspan="3" {...props} />
                                <TableCell type='td' item='activity' name='RISE Conf.' rowspan="3"{...props} />
                                <TableCell type='td' item='activity' name='Break' colspan='3'{...props} />
                                <TableCell type='td' item='activity' name='Break'{...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 16:30' eventTime={eventTime}{...props} /> 
                                <TableCell type='td' item='activity' name='Tutoriales' rowspan="2" colspan='3' {...props} />
                                <TableCell type='td' item='activity' name='LAC PF' rowspan="2" {...props} />
                                <TableCell type='td' item='activity' name='Foro Técnico' rowspan="2"{...props} />
                                <TableCell type='td' item='activity' name='LACNIC' rowspan="2"{...props} />
                                <TableCell type='td' item='activity' name='Tutoriales' rowspan="2"{...props} />
                                <TableCell type='td' item='activity' name='RISE Training' rowspan="2"{...props} />
                                <TableCell type='td' item='activity' name='LACNIC' {...props} />
                            </tr>


                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 17:00' eventTime={eventTime} {...props} />
 
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 18:00' eventTime={eventTime} {...props} />
                                <TableCell type='td' item='empty'  {...props} />
                                <TableCell type='td' item='empty' colspan="4"{...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='empty' colspan="2"{...props} />
                                <TableCell type='td' item='empty' colspan="3"{...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 19:00' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='social' item='activity' social={() => handleSocial('cocktail')} name='Cóctel de Bienvenida' colspan="4" rowspan="3" {...props} />
                                <TableCell type='td' item='activity'  {...props} />
                                <TableCell type='td' item='empty' colspan='2'{...props} />
                                <TableCell type='td' item='empty' colspan='3'{...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 20:00' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='empty'  {...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='social' item='activity' social={() => handleSocial('social')} name='Evento Social' colspan="2" rowspan="5" {...props} />
                                <TableCell type='td' item='empty' colspan='3'{...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 21:00' eventTime={eventTime} {...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='empty'  {...props}  />
                                <TableCell type='td' item='empty' {...props} colspan="3" />
                                <TableCell type='td' item='empty' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 22:00' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='empty' {...props} />

                                <TableCell type='td' item='empty' colspan='4'{...props} />
                                <TableCell type='td' item='empty'  {...props} />
                                <TableCell type='td' item='empty' colspan='3'{...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 23:00' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='empty' colspan='4'{...props} />
                                <TableCell type='td' item='empty'  {...props} /> 
                                <TableCell type='td' item='empty' colspan='3'{...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </tr>
                            <tr>
                                <TableCell type='td' item='time' time='08/05/2022 00:00' eventTime={eventTime}{...props} />
                                <TableCell type='td' item='empty' {...props} />
                                <TableCell type='td' item='empty' colspan='4'{...props} />
                                <TableCell type='td' item='empty'  {...props} /> 
                                <TableCell type='td' item='empty' colspan='3'{...props} />
                                <TableCell type='td' item='empty' {...props} />
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <i className='icon-mouse-scroll-left-right icon'></i>
                <SocialActivities display={display} closeModal={closeModal} socialActivity={socialActivity}/>
                 
            </div>
        </article>

    )
}

export default Timetable




