import React from 'react'

const ToogleBoxButton = props =>{
    const { onClick, boxState } = props;
    return (
        <div className={`toogle_button ${ boxState === 'disable' ? '' : 'active'}`} onClick={onClick}> 
            <span >
            
            </span>
        </div>
    )
}

export default ToogleBoxButton
