import React, {useEffect} from 'react'
import UseHTML from '../../../components/hook/UseHTML';
import UrlAndCode from '../../../components/interface/url-and-code/UrlAndCode';
import AccessCode from './AccessCode';
import JoinActivity from './JoinActivity';

const Webinar = props => {
    const { webinar_label, webinar_content } = props; 

        useEffect(() => {
            const hash = window.location.hash; // obtener el hash de la URL
            if (hash) {
              const element = document.querySelector(hash); // buscar el elemento que coincide con el hash
              if (element) {
                element.scrollIntoView(); // hacer scroll al elemento
              }
            }
          }, []);

    const active = false;
 
    return (
        <>
        <article className='preparatory_activity policy_proposals' id="policy-proposals">
                <div>
                    <h2 className='preparatory_title'>{webinar_label[1]}</h2>
                    <UseHTML className="preparatory_content" html={webinar_content[1]} />
                    {
                        active ? <>
                        <JoinActivity />
                        <AccessCode />
                        <UrlAndCode url_text='https://us02web.zoom.us/j/89605969716' url='https://us02web.zoom.us/j/89605969716' code='983665' /> 
                        </>
                    : undefined }
                </div>
            </article>
            <article className='preparatory_activity introduction_to_the_linux_console' id="linux">
                <div>
                    <h2 className='preparatory_title'>{webinar_label[2]}</h2>
                    <UseHTML className="preparatory_content" html={webinar_content[2]} />
                    {
                        active ? <> 
                        <JoinActivity />
                        <AccessCode />
                        <UrlAndCode url_text='https://us02web.zoom.us/j/88978753928' url='https://us02web.zoom.us/j/88978753928?pwd=Y3lsejJTaTVqbFcrbzlTMmZQSk9FQT09' code='723281' />
                    </>
                    : undefined }
                </div>
            </article>
             
            <article className='preparatory_activity lacnic39_in_merida' id="lacnic39-in-merida">
                <div>
                    <h2 className='preparatory_title'>{webinar_label[0]}</h2>
                    <UseHTML className="preparatory_content" html={webinar_content[0]} />
                </div>
            </article>
        </>

    )
}

export default Webinar
