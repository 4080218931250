import React from 'react';  
import UseHTML from '../../../components/hook/UseHTML'; 
import UseLang from '../../../components/hook/UseLang';
import Alert from '../../../components/interface/alert/Alert';

const FellowshipProgramHeader = props => { 
    const { content, title, button } = props;

    return (
        <article className='fellowship_program_main_header'> 
            <div>
                <h1><UseHTML html={title} /></h1>
                <UseHTML html={content} />
                {button.button_external_link ? 
                    <a href={`${button.button_url}`} target="_blank" className='button blue'  rel="noreferrer">{button.button_name}</a>
                    : 
                        <Alert red>
                            <UseLang es><p>El período para solicitar becas ha finalizado.</p></UseLang>
                            <UseLang en><p>The period to apply for grants is over.</p></UseLang>
                            <UseLang pt_br><p>O período de pedido de bolsa acabou.</p></UseLang>
                        </Alert>
                }
            </div>
        </article>
    )
}

export default FellowshipProgramHeader
