import React, { useContext, useState } from 'react';
import { PostContext } from '../../../context/DataContext'; 
import UsePost from '../../../components/hook/UsePost';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import Header from '../../../components/navegation/header/Header';
import Footer from '../../../components/navegation/footer/Footer';
import Main from '../../../components/interface/main/Main'; 
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import './welcome-kit.css'
import UseLangLocation from '../../../components/hook/UseLangLocation';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';
import WelcomeKitHeader from './WelcomeKitHeader';
import DigitalBadge from './DigitalBadge';
import Filters from './Filters';
import KitSocialNetworks from './KitSocialNetworks';

const WelcomeKit = props => { 
    const { handleID, title, handleApiURL, handleLoading, status, accordion  } = useContext(PostContext);
    const [loading, setLoading] = useState(true);

    const header =  accordion?.map(info => info.label)
    const content =  accordion?.map(info => info.content) 

    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/participate' handleLoading={handleLoading} handleID={handleID} id='1401'>
                <Header title={title} loading={loading} url_en='/en/how-to-participate/welcome-kit' url_es='/es/como-participar/kit-de-bienvenida' url_pt_br='/pt-br/como-participar/kit-de-boas-vindas' {...props} />
                <Main className='container welcome_kit' loading={loading} status={status}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}> 
                            <SponsorsSlider />
                            <StickyHeader/>
                            <article> 
                                <div> 
                                    <WelcomeKitHeader title={title} />
                                    <div className='welcome_kit_tools'> 
                                        <DigitalBadge header={header[0]} content={content[0]} />
                                        <Filters header={header[1]} content={content[1]} />
                                    </div>
                                    <KitSocialNetworks header={header[2]} content={content[2]} />
                                </div>
                            </article>
                            
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default WelcomeKit
