import React from 'react'
import UseHTML from '../../../components/hook/UseHTML'

const VisasHeader = props => {
    const { title, content } = props;
    return (
        <article className='visas_header'>
            <div>
                <h1><UseHTML html={title} /></h1>
                <UseHTML html={content} />
            </div>
        </article>
    )
}

export default VisasHeader
