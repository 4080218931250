import React, { useContext,   useState } from 'react';
import { PostContext } from '../../../context/DataContext';
import Main from '../../../components/interface/main/Main';
import Footer from '../../../components/navegation/footer/Footer';
import Header from '../../../components/navegation/header/Header'; 
import UsePost from '../../../components/hook/UsePost';  
import OrganizersElement from '../../../components/design/organizers/OrganizersElement';
import UseTimeOut from '../../../components/hook/UseTimeOut';
import './travel-discounts.css'
import UseLangLocation from '../../../components/hook/UseLangLocation';
import CopaDiscounts from './CopaDiscounts';
import CopaRestrictions from './CopaRestrictions';
import SponsorsSlider from '../../../components/sections/sponsors/SponsorsSlider';
import StickyHeader from '../../../components/interface/sticky-header /StickyHeader';

const TravelDiscounts = props => { 
    const { handleID, title, handleApiURL, accordion, handleLoading, status } = useContext(PostContext);
    const [loading, setLoading] = useState(true); 
  
    const headers =  accordion?.map(info => info.label)
    const contents =  accordion?.map(info => info.content) 

    return (
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/wp/v2/travel' handleLoading={handleLoading} handleID={handleID} id='1050' >
                <Header title={title} url_en='/en/hotels-and-travel/travel-discounts' url_pt_br='/pt-br/hoteis-e-viagem/descontos-em-passagens' url_es='/es/hoteles-y-viaje/descuentos-en-pasajes' loading={loading} {...props} />
                <Main status={status} className='container travel_discounts' loading={loading}  >
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}>
                             
                            <SponsorsSlider/>
                            <StickyHeader/>
                            <CopaDiscounts headers={headers} contents={contents}/>
                            <CopaRestrictions headers={headers} contents={contents}/>
                        </UseTimeOut>
                    </section>
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation>
    )
}

export default TravelDiscounts
