import React, { useContext,  useState } from 'react';  
import OrganizersElement from '../../../../components/design/organizers/OrganizersElement';
import UseLangLocation from '../../../../components/hook/UseLangLocation';
import UsePost from '../../../../components/hook/UsePost';
import UseTimeOut from '../../../../components/hook/UseTimeOut';
import Main from '../../../../components/interface/main/Main';
import StickyHeader from '../../../../components/interface/sticky-header /StickyHeader';
import Footer from '../../../../components/navegation/footer/Footer';
import Header from '../../../../components/navegation/header/Header';
import SponsorsSlider from '../../../../components/sections/sponsors/SponsorsSlider';
import { PostContext, BiographyContext } from '../../../../context/DataContext';
import SpeakersList from './SpeakersList';

const Speakers = props => { 
    const {  handleApiURL, handleID, title,  handleLoading, status } = useContext(PostContext);
    const { bios } = useContext(BiographyContext);
    const [loading, setLoading] = useState(true);
 
 
    return ( 
        <UseLangLocation>
            <UsePost enabled handleApiURL={handleApiURL} url='https://apievt.lacnic.net/wp-json/data/speakers' handleLoading={handleLoading} handleID={handleID} id='1350' >
                <Header title={title} url_es='/es/oradores/lista-de-oradores' url_pt_br='/pt-br/palestrantes/lista-de-palestrantes' url_en='/en/speakers/speakers-list' loading={loading} {...props} />
                <Main className='container' status={status} loading={loading}>
                    <section>
                        <UseTimeOut timeout='700' loading={loading} setLoading={setLoading} title={title}>
                            <SponsorsSlider/> 
                            <StickyHeader/> 
                            <SpeakersList title={title} bios={bios}/>
                            
                        </UseTimeOut>
                    </section>   
                </Main>
                <OrganizersElement />
                <Footer className="master_foot foot_home" />
            </UsePost>
        </UseLangLocation> 
    )
}

export default Speakers
