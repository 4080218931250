import React from 'react'
import UseHTML from '../../../../components/hook/UseHTML';

const AgendaHeader = props => {
    const { title } = props;
    return (
        <article className='agenda_header'>
            <div>
                <h1><UseHTML html={title} /></h1>
            </div>
        </article>
    )
}

export default AgendaHeader
