import React from 'react' 
import UseLang from '../../../../../../components/hook/UseLang';

const PresentationTitle = props => {
    const {title} = props; 
    return (
        <h3 className='presentation_title'> 
            <UseLang es>{title.info_es.title}</UseLang>
            <UseLang en>{title.info_en.title}</UseLang>
            <UseLang pt_br>{title.info_pt_br.title}</UseLang>
        </h3>
    )
}

export default PresentationTitle
