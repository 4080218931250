import React from 'react'
import UseLang from '../../../../components/hook/UseLang'

const Cocktail = () => {
    return (
        <>
            <div className='social_activity_image'>
            <img src="/images/hyatt-merida.png" alt="Paseo Montejo" />
                 
            </div>
            <div className='social_activity_content'>
                <UseLang es> 
                    <h1>Cóctel de Bienvenida</h1>
                    <p className="presentation_start_time">8/5/2023, 19:00 - 22:00 (Mérida)</p>
                    <p>Terraza del Hotel Hyatt Regency Mérida - 6º piso </p>
                    <p>Av. Colón 344. Zona Paseo Montejo, Centro</p>
                </UseLang>
                <UseLang en>
                    <h1>Welcome Cocktail</h1>
                    <p className="presentation_start_time">8/5/2023, 19:00 - 22:00 (Mérida)</p>
                    <p>Hyatt Regency Mérida Hotel terrace - 6º floor </p>
                    <p>Av. Colón 344. Zona Paseo Montejo, Centro</p>  
                </UseLang>
                <UseLang pt_br>
                    <h1>Coquetel de Boas vindas</h1>
                    <p className="presentation_start_time">8/5/2023, 19:00 - 22:00 (Mérida)</p>
                    <p>Terraço do Hotel Hyatt Regency Mérida - 6º andar</p>
                    <p>Av. Colón 344. Zona Paseo Montejo, Centro</p>
                     
                </UseLang>
            </div>
        </>
    )
}

export default Cocktail
