import React from 'react'
import slugify from 'react-slugify';
import UseLang from '../../../../components/hook/UseLang';

const SpeakersList = props => {
    const {title, bios}= props;
    return (
        <article className='speakers'>
            <div>
                <h1>{title}</h1>
                <div className='speakers_list'>
                    {bios.sort((a, b) => ( a.title.rendered > b.title.rendered ? 1 : -1 ))?.map((item, id) =>{
                        const { title, acf } = item
                        return (
                        <div>
                            {
                                acf.picture_default === false ? <img src={acf.photo} className='photo' alt={title}/>
                                
                                : <>
                                    {acf.sex === false ? <img src='/default-man.png' className='photo' alt={title}/> 
                                    : <img src='/default-woman.png' className='photo' alt={title}/>}
                                </>
                            }
                            {
                                acf.biografia_pendiente === false ? <>
                                <UseLang es>
                                <a href={`./${slugify(title.rendered)}`}>{title.rendered}</a> 
                                </UseLang>
                                <UseLang en>
                                    <a href={`./${slugify(title.rendered)}`}>{title.rendered}</a> 
                                </UseLang>
                                <UseLang pt_br>
                                    <a href={`./${slugify(title.rendered)}`}>{title.rendered}</a> 
                                </UseLang>
                                </>: <p>{title.rendered}</p>
                            }
                            
                            
                        </div>
                        )
                    })}
                </div>
            </div>
        </article>
    )
}

export default SpeakersList
